import dayjs from 'dayjs'
import { Form, FormikProvider, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { activityFields } from '../../common/forms/activities/fields'
import { activityResponseFields } from '../../common/response/activity'
import { companyResponseFields } from '../../common/response/company'
import { formatDateTime } from '../../utils/general'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../AppPermissionsSwitcher/constants/actions'
import { DeleteButton, SaveButton } from '../Common/Buttons'
import RecursiveForm from '../RecursiveForm'

const ActivityModal = ({ isEdit, modal, toggle, event, showEditForm, validation, submitOtherEvent, inputs, selectOptions, toggleDeleteModal }) => {
    const { t } = useTranslation()

    // data needed for permissions
    // contactPersonCompany - company that was added to contact
    // contactCompany - standalone company
    const activityAgentId = event?.activityAgent?.[0]?.agent?.id
    const contactAgentId = event?.contact?.agent?.id
    const contactAgencyOfficeId = event?.contact?.agent?.agencyOffice?.id
    const companyAgentId = event?.contact?.contactPersonCompany?.[0]?.company?.agent?.id ?? event?.contactCompany?.[0]?.agent?.id
    const companyAgencyOfficeId =
        event?.contact?.contactPersonCompany?.[0]?.company?.agent?.agencyOffice?.id ?? event?.contactCompany?.[0]?.agent?.agencyOffice?.id

    const showContactData = event?.contact?.contactPerson || event?.contactCompany?.length > 0

    const projectValue = getIn(validation.values, 'project_id')
    const propertyValue = getIn(validation.values, 'property_id')
    const durationDayValue = getIn(validation.values, 'duration_day')
    const activityDateFromValue = getIn(validation.values, activityFields.DATE_FROM)
    const activityTimeFromValue = getIn(validation.values, activityFields.TIME_FROM)
    const [modifiedInputs, setModifiedInputs] = useState([])

    useEffect(() => {
        if (inputs) {
            setModifiedInputs(inputs)
        } else {
            setModifiedInputs([])
        }
    }, [inputs])

    // effect for disabling projectId input if propertyId is filled & vice versa
    useEffect(() => {
        const projectIndex = inputs?.mainForm?.findIndex((x) => x?.name === 'project_id')
        const propertyIndex = inputs?.mainForm?.findIndex((x) => x?.name === 'property_id')

        if (!inputs?.mainForm) return

        const changed = { ...inputs }

        if (projectIndex !== -1) {
            changed.mainForm[projectIndex].disabled = !!propertyValue?.length
        }

        if (propertyIndex !== -1) {
            changed.mainForm[propertyIndex].disabled = !!projectValue?.length
        }

        setModifiedInputs({ ...changed })
    }, [projectValue, propertyValue, inputs])

    useEffect(() => {
        const durationFromIndex = inputs?.mainForm?.findIndex((x) => x.name === 'durationFromGrouped')
        const durationToIndex = inputs?.mainForm?.findIndex((x) => x.name === 'durationToGrouped')
        const timeFromIndex = inputs?.mainForm?.[durationFromIndex]?.items?.findIndex((x) => x.name === activityFields.TIME_FROM)
        const timeToIndex = inputs?.mainForm?.[durationToIndex]?.items?.findIndex((x) => x.name === activityFields.TIME_TO)

        if (durationDayValue) {
            if (inputs.mainForm) {
                const changed = { ...inputs }
                if (timeFromIndex > -1) {
                    changed.mainForm[durationFromIndex].items[timeFromIndex].disabled = true
                }
                if (timeToIndex > -1) {
                    changed.mainForm[durationToIndex].items[timeToIndex].disabled = true
                }
                setModifiedInputs({ ...changed })
                validation.setFieldValue(activityFields.TIME_FROM, '08:00')
                validation.setFieldValue(activityFields.TIME_TO, '20:00')
            }
        } else {
            if (inputs.mainForm) {
                const changed = { ...inputs }
                if (timeFromIndex > -1) {
                    changed.mainForm[durationFromIndex].items[timeFromIndex].disabled = false
                }
                if (timeToIndex > -1) {
                    changed.mainForm[durationToIndex].items[timeToIndex].disabled = false
                }

                setModifiedInputs({ ...changed })

                validation.setFieldValue(activityFields.TIME_FROM, dayjs().format('HH:mm'))
                validation.setFieldValue(activityFields.TIME_TO, dayjs().add(15, 'minute').format('HH:mm'))
            }
        }
    }, [durationDayValue])

    // Effect to set date to value to date from value if date from is after date to
    useEffect(() => {
        const activityDateToValue = getIn(validation.values, activityFields.DATE_TO)
        const isDateFromAfterDateTo = dayjs(activityDateFromValue).isAfter(dayjs(activityDateToValue))
        if (isDateFromAfterDateTo) {
            validation.setFieldValue(activityFields.DATE_TO, activityDateFromValue)
        }
    }, [activityDateFromValue])

    // Effect to set time to value to 15 minutes after time from value
    useEffect(() => {
        const dateTime = `${activityDateFromValue}T${activityTimeFromValue}`
        const isDateValid = dayjs(dateTime).isValid()

        if (!durationDayValue && isDateValid) {
            validation.setFieldValue(activityFields.TIME_TO, dayjs(dateTime).add(15, 'minute').format('HH:mm'))
        }
    }, [activityTimeFromValue])

    return (
        <Modal isOpen={modal} id="event-modal" toggle={toggle}>
            <ModalHeader
                toggle={toggle}
                tag="h5"
                className={`p-3 ${!isEdit ? 'bg-soft-add' : ''} modal-title`}
                style={{ background: event?.backgroundColor ? event.backgroundColor : '' }}
            >
                {isEdit ? `${event.title ? event.title : t('modal.activity.editActivity')}` : t('app.common.addActivity')}
            </ModalHeader>
            <ModalBody>
                <FormikProvider value={validation}>
                    <Form
                        name="event-form"
                        id="form-event"
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                    >
                        {isEdit && !showEditForm ? (
                            <div className="text-end">
                                <AppPermissionsSwitcher
                                    permission="ModulePermissions"
                                    activityAgentId={activityAgentId}
                                    module={'activities'}
                                    action={action.edit}
                                >
                                    {({ hasModulePermission }) =>
                                        hasModulePermission && (
                                            <Button
                                                className="btn btn-sm btn-edit"
                                                id="edit-event-btn"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    submitOtherEvent()
                                                    return false
                                                }}
                                            >
                                                {t('button.edit')}
                                            </Button>
                                        )
                                    }
                                </AppPermissionsSwitcher>
                            </div>
                        ) : null}

                        {/* activity basic info data/modal */}
                        {isEdit && !showEditForm && (
                            <div>
                                {showContactData && (
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <i className="mdi mdi-calendar-account-outline text-secondary fs-16"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="d-block fw-light mb-0" id="event-start-date-tag">
                                                    <AppPermissionsSwitcher
                                                        permission="ModulePermissions"
                                                        itemAgentId={contactAgentId}
                                                        itemAgentOfficeId={contactAgencyOfficeId}
                                                        module={'contacts'}
                                                        action={action.view}
                                                    >
                                                        {({ hasModulePermission }) =>
                                                            event?.[activityResponseFields.CONTACT]?.contactPerson && (
                                                                <>
                                                                    <Link
                                                                        to={
                                                                            hasModulePermission
                                                                                ? `/contacts/${event[activityResponseFields.CONTACT].id}`
                                                                                : '#'
                                                                        }
                                                                        target={hasModulePermission && '_blank'}
                                                                        className={hasModulePermission && 'dmn-link'}
                                                                        style={{ cursor: hasModulePermission ? 'pointer' : 'auto' }}
                                                                    >
                                                                        {event[activityResponseFields.CONTACT].contactPerson.firstName}{' '}
                                                                        {event[activityResponseFields.CONTACT].contactPerson.lastName}
                                                                    </Link>
                                                                    {event?.[activityResponseFields.CONTACT_COMPANY].length > 0 && ', '}
                                                                </>
                                                            )
                                                        }
                                                    </AppPermissionsSwitcher>

                                                    <AppPermissionsSwitcher
                                                        permission="ModulePermissions"
                                                        itemAgentId={companyAgentId}
                                                        itemAgentOfficeId={companyAgencyOfficeId}
                                                        module={'companies'}
                                                        action={action.view}
                                                    >
                                                        {({ hasModulePermission }) =>
                                                            event?.[activityResponseFields.CONTACT_COMPANY]?.map((item, index, array) => (
                                                                <React.Fragment key={item.id}>
                                                                    <Link
                                                                        to={hasModulePermission ? `/companies/${item.id}` : '#'}
                                                                        target={hasModulePermission && '_blank'}
                                                                        className={hasModulePermission && 'dmn-link'}
                                                                        style={{ cursor: hasModulePermission ? 'pointer' : 'auto' }}
                                                                    >
                                                                        {item.contactPersonCompany?.[0]?.company?.contactCompany?.name ??
                                                                            item.contactCompany?.name}
                                                                    </Link>
                                                                    {index < array.length - 1 && ', '}
                                                                </React.Fragment>
                                                            )) ?? ''
                                                        }
                                                    </AppPermissionsSwitcher>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {event?.activityType && (
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <i className="mdi mdi-calendar-text-outline text-secondary fs-16"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="d-block fw-light mb-0" id="event-start-date-tag">
                                                    {event.activityType.name},{' '}
                                                    {event.completed ? (
                                                        <span className="text-success">{t('modal.activity.field.completed')}</span>
                                                    ) : (
                                                        t('modal.activity.field.planned')
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(event?.[activityResponseFields.DATE_FROM] || event?.[activityResponseFields.DATE_TO]) && (
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <i className="mdi mdi-calendar text-secondary fs-16"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="d-block fw-light mb-0" id="event-start-date-tag">
                                                    {`od ${formatDateTime(event[activityResponseFields.DATE_FROM])} `}
                                                    {`do ${formatDateTime(event[activityResponseFields.DATE_TO])}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(event?.[activityResponseFields.TIME_FROM] || event?.[activityResponseFields.TIME_TO]) && (
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <i className="mdi mdi-clock-outline text-secondary fs-16"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="d-block fw-light mb-0" id="event-start-date-tag">
                                                    {event && event[activityResponseFields.TIME_FROM]
                                                        ? `od ${event[activityResponseFields.TIME_FROM]} `
                                                        : ''}
                                                    {event && event[activityResponseFields.TIME_TO]
                                                        ? `do ${event[activityResponseFields.TIME_TO]}`
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {event?.location && (
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <i className="mdi mdi-map-marker-outline text-secondary fs-16"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="d-block fw-light mb-0" id="event-start-date-tag">
                                                    {event.location}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {event?.[companyResponseFields.NOTES] && (
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <i className="mdi mdi-message-text-outline text-secondary fs-16"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="d-block fw-light text-secondary mb-0" id="event-start-date-tag">
                                                    {event[companyResponseFields.NOTES]}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {event?.[activityResponseFields.ACTIVITY_AGENT] && event?.[activityResponseFields.ACTIVITY_AGENT].length && (
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <i className="mdi mdi-account-outline text-secondary fs-16"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="d-block fw-light mb-0" id="event-start-date-tag">
                                                    {event?.[activityResponseFields.ACTIVITY_AGENT]?.[0]?.[activityResponseFields.AGENT]?.firstName}{' '}
                                                    {event?.[activityResponseFields.ACTIVITY_AGENT]?.[0]?.[activityResponseFields.AGENT]?.lastName}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <Row className="event-form">
                            {isEdit ? (
                                showEditForm ? (
                                    <RecursiveForm inputs={modifiedInputs.mainForm} formik={validation} selectOptions={selectOptions} />
                                ) : null
                            ) : (
                                <RecursiveForm inputs={modifiedInputs.mainForm} formik={validation} selectOptions={selectOptions} />
                            )}
                        </Row>
                        <div className="hstack gap-2 justify-content-end">
                            {isEdit && (
                                <AppPermissionsSwitcher
                                    permission="ModulePermissions"
                                    activityAgentId={activityAgentId}
                                    module={'activities'}
                                    action={action.delete}
                                >
                                    {({ hasModulePermission }) =>
                                        hasModulePermission && <DeleteButton id="btn-delete-event" onClick={() => toggleDeleteModal(event)} />
                                    }
                                </AppPermissionsSwitcher>
                            )}
                            {isEdit ? showEditForm ? <SaveButton /> : null : <SaveButton />}
                        </div>
                    </Form>
                </FormikProvider>
            </ModalBody>
        </Modal>
    )
}

export default ActivityModal
