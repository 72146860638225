import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import i18n from '../../i18n'
import { DeleteMultipleResponse, PageListParams } from '../globalTypes'
import { invalidateQuery, updateToast } from '../helpers'
import { createUpdateProject, deleteProjectById, deleteProjects, getProjectById, getProjects } from './functions'
import { Project, ProjectDeleteParams, ProjectPayload, ProjectResponse, ProjectsDeleteParams } from './types'

const projects = {
    GetProject: {
        useQuery: (id: number, refreshToken: string, options?: UseQueryOptions<Project, AxiosError>) =>
            useQuery({
                queryKey: ['projects', id, refreshToken],
                queryFn: () => getProjectById(id),
                staleTime: 60 * 1000,
                ...options,
            }),
    },
    GetProjects: {
        useQuery: (params: PageListParams, refreshToken: string, options?: UseQueryOptions<Project[], AxiosError>) =>
            useQuery({
                queryKey: ['projects', 'list', refreshToken, params],
                queryFn: () => getProjects(params),
                staleTime: 60 * 1000,
                ...options,
            }),
    },
    CreateUpdateProject: {
        useMutation: (options?: UseMutationOptions<ProjectResponse, AxiosError, ProjectPayload>) => {
            const history = useHistory()

            return useMutation({
                onMutate: () => {
                    const toastId = toast.loading(i18n.t('toast.loading') as string)
                    return toastId
                },
                mutationFn: (payload: ProjectPayload) => createUpdateProject(payload),
                onSuccess: (data, variables, context) => {
                    const { isEdit } = variables

                    const toastId = context as number
                    toastId && updateToast(toastId, i18n.t('toast.update.success') as string, 'success')

                    invalidateQuery(['projects', data.id.toString()])
                    invalidateQuery(['projects', 'list'])

                    !isEdit && history.push(`/projects/${data.id}/edit`)
                },
                onError: (error, variables, context) => {
                    const toastId = context as number
                    toastId && updateToast(toastId, i18n.t('toast.update.error') as string, 'error')
                },
                ...options,
            })
        },
    },
    DeleteProject: {
        useMutation: (options?: UseMutationOptions<ProjectResponse, AxiosError, ProjectDeleteParams>) => {
            return useMutation({
                onMutate: (params: ProjectDeleteParams) => {
                    toast.loading(i18n.t('toast.loading.delete') as string, { toastId: params.id })
                },
                mutationFn: (params: ProjectDeleteParams) => deleteProjectById(params.id),
                onSuccess: (data, params) => {
                    const { id } = params ?? {}
                    updateToast(id, i18n.t('toast.update.delete.success') as string, 'success')

                    invalidateQuery(['projects', 'list'])
                },
                onError: (error, params) => {
                    updateToast(params.id, i18n.t('toast.update.delete.error') as string, 'error')
                    console.log('Error deleting project:', error)
                },
                ...options,
            })
        },
    },
    DeleteProjects: {
        useMutation: (options?: UseMutationOptions<DeleteMultipleResponse, AxiosError, ProjectsDeleteParams>) => {
            return useMutation({
                onMutate: (params: ProjectsDeleteParams) => {
                    const { ids } = params
                    toast.loading(i18n.t('toast.loading.delete.project.number', { requestsToBeDeletedCount: ids.length }) as string, {
                        toastId: ids[0],
                    })
                },
                mutationFn: (params: ProjectsDeleteParams) => deleteProjects(params.ids),
                onSuccess: (data, params) => {
                    const { ids } = params ?? {}

                    const failedCount = data?.notFoundIds?.length
                    const successCount = data?.successfulDelete?.length
                    const requestsToBeDeletedCount = ids?.length

                    const successMessage = i18n.t('toast.loading.delete.project.number.success', { successCount, requestsToBeDeletedCount }) as string
                    const partialErrorMessage = i18n.t('toast.loading.delete.project.number.error', {
                        failedCount,
                        requestsToBeDeletedCount,
                    }) as string

                    if (failedCount > 0) {
                        updateToast(ids?.[0], partialErrorMessage, 'error')
                    }

                    if (successCount > 0) {
                        updateToast(ids?.[0], successMessage, 'success')
                        invalidateQuery(['projects', 'list'])
                    }
                },
                onError: (error, params) => {
                    const { ids } = params ?? {}
                    updateToast(ids?.[0], i18n.t('toast.update.delete.error'), 'error')
                    console.error(error)
                },
                ...options,
            })
        },
    },
}

export default projects
