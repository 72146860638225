import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import ActivityColumns from './OpportunityTable/TableColumns/ActivityColumns'
import ContactColumns from './OpportunityTable/TableColumns/ContactColumns'
import { selectOpportunityShowActivity } from '../../store/properties/selectors'
import OpportunityTable from './OpportunityTable/OpportunityTable'

const OpportunityList = () => {
    const { showActivity } = useSelector((state) => ({
        showActivity: selectOpportunityShowActivity(state),
    }))

    return (
        <Card>
            <CardBody>
                <OpportunityTable
                    columns={showActivity ? ActivityColumns() : ContactColumns()}
                    url={showActivity ? 'activity' : 'contact/profile'}
                    tableHash={showActivity ? 'opportunityActivityTable' : 'opportunityContactTable'}
                />
            </CardBody>
        </Card>
    )
}

export default OpportunityList
