import FullCalendar from '@fullcalendar/react' // IMPORTANT! : needs to be imported first (before other @fullcalendar plugins), otherwise breaks

import BootstrapTheme from '@fullcalendar/bootstrap'
import deLocale from '@fullcalendar/core/locales/de'
import enLocale from '@fullcalendar/core/locales/en-gb'
import hrLocale from '@fullcalendar/core/locales/hr'
import slLocale from '@fullcalendar/core/locales/sl'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import '@fullcalendar/react/dist/vdom'
import dayjs from 'dayjs'
import { getIn, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap'
import * as Yup from 'yup'
import { USER_ROLES } from '../../common/constants'
import { activityFields } from '../../common/forms/activities/fields'
import { activityResponseFields } from '../../common/response/activity'
import AppPermissionsSwitcher from '../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import DeleteModal from '../../Components/Common/DeleteModal'
import Loader from '../../Components/Common/Loader'
import PageTitle from '../../Components/Common/PageTitle'
import { useActivityFormConfig, useActivityFormSelectOptions } from '../../Components/Hooks/FormHooks'
import ActivityModal from '../../Components/Modals/ActivityModal'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import i18n from '../../i18n'
import { clearActivities, createActivity, deleteActivity, fetchActivities, updateActivity } from '../../store/activities'
import { selectActivities, selectActivitiesFetching, selectActivity } from '../../store/activities/selectors'
import { selectActivityValuesType, selectActivityValuesTypeFetching } from '../../store/activityValues/selectors'
import { selectAgentsFormOption } from '../../store/agents/selectors'
import { updateCurrentSettings } from '../../store/auth/actions'
import ExternalEvents from './ExternalEvents'

const hrLocaleModified = {
    ...hrLocale,
    buttonText: {
        ...hrLocale.buttonText,
        list: 'Lista',
    },
}

const availableCalendarLanguages = [
    { code: 'hr', local: hrLocaleModified },
    { code: 'de', local: deLocale },
    { code: 'en', local: enLocale },
    { code: 'sl', local: slLocale },
]

const ActivityCalendar = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { user, userSettings } = useCurrentUser()

    const [event, setEvent] = useState({})
    const [modal, setModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [acType, setAcType] = useState(null)
    const [dateRange, setDateRange] = useState(null)

    const [selectedAgents, setSelectedAgents] = useState(() => {
        if (user && user?.roles?.includes(USER_ROLES.ROLE_ADMIN)) {
            return [user.id?.toString()]
        }
        return []
    })
    const [selectedActivityTypes, setSelectedActivityTypes] = useState(user && userSettings?.activityTypes ? userSettings.activityTypes : [])

    const selectOptions = useActivityFormSelectOptions(event?.id)

    const selectedLocale = useMemo(() => {
        const localeObj = availableCalendarLanguages.find((loc) => loc.code === i18n.language)
        return localeObj.local
    }, [])

    const { activities, activity, activityTypes, fetchingActivities, agentOptions } = useSelector((state) => ({
        activities: selectActivities(state),
        fetchingActivities: selectActivitiesFetching(state),
        activity: selectActivity(state, event?.id),
        activityTypes: selectActivityValuesType(state),
        activityTypeFetching: selectActivityValuesTypeFetching(state),
        agentOptions: selectAgentsFormOption(state),
    }))

    const { inputs, validationConfig } = useActivityFormConfig(event, acType)

    useEffect(() => {
        return () => {
            dispatch(clearActivities())
        }
    }, [dispatch])

    useEffect(() => {
        const externalEvents = document.getElementById('external-events')

        if (externalEvents) {
            new Draggable(document.getElementById('external-events'), {
                itemSelector: '.external-event',
            })
        }
    }, [])

    useEffect(() => {
        if (!dateRange) return

        dispatch(
            fetchActivities({
                noLimit: 1,
                criteria: {
                    rangeFrom: dateRange?.from,
                    rangeTo: dateRange?.to,
                    activityType: selectedActivityTypes,
                    agent: selectedAgents,
                },
            })
        )
    }, [dateRange, selectedActivityTypes, selectedAgents, dispatch])

    /**
     * Handling the modal state
     */
    const toggle = () => {
        if (modal) {
            setModal(false)
            setEvent(null)
            setIsEdit(false)
            setShowEditForm(false)
        } else {
            setModal(true)
        }
    }

    const handleAddButton = () => {
        setEvent({
            [activityResponseFields.DATE_FROM]: dayjs().format('YYYY-MM-DD'),
            [activityResponseFields.TIME_FROM]: dayjs().format('HH:mm'),
            [activityResponseFields.TIME_TO]: dayjs().add(15, 'minute').format('HH:mm'),
            [activityResponseFields.DATE_TO]: dayjs().format('YYYY-MM-DD'),
        })

        toggle()
    }

    const handleDateSet = (arg) => {
        setDateRange({
            from: dayjs(arg.start).format('YYYY-MM-DD'),
            to: dayjs(arg.end).format('YYYY-MM-DD'),
        })
    }

    /**
     * Handling date click on calendar
     */
    const handleDateClick = (arg) => {
        const date = arg['date']
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

        const currectDate = new Date()
        const currentHour = currectDate.getHours()
        const currentMin = currectDate.getMinutes()
        const currentSec = currectDate.getSeconds()
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec)

        setEvent({
            [activityResponseFields.DATE_FROM]: dayjs(modifiedDate).format('YYYY-MM-DD'),
            [activityResponseFields.TIME_FROM]: dayjs(modifiedDate).format('HH:mm'),
            [activityResponseFields.TIME_TO]: dayjs().add(15, 'minute').format('HH:mm'),
            [activityResponseFields.DATE_TO]: dayjs(modifiedDate).format('YYYY-MM-DD'),
        })
        toggle()
    }

    /**
     * Handling click on event on calendar
     */
    const handleEventClick = (arg) => {
        const event = arg.event
        setEvent({ id: event.id })
    }

    const handleActivityTypeChange = (newActivityTypes) => {
        if (user && user.id) {
            dispatch(
                updateCurrentSettings({
                    userId: user.id,
                    userSettings: {
                        ...userSettings,
                        activityTypes: newActivityTypes,
                    },
                })
            )
        }
    }

    useEffect(() => {
        if (activity) {
            setEvent({ ...activity })
            setIsEdit(true)
            setShowEditForm(false)
            toggle()
        } else {
            setEvent(null)
            setIsEdit(false)
            setShowEditForm(false)
        }
    }, [activity])

    /**
     * On delete event
     */
    const handleDeleteEvent = () => {
        dispatch(deleteActivity(event.id))
        setDeleteModal(false)
        toggle()
    }

    // activities validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: validationConfig.initialValues ?? {},
        validationSchema: validationConfig.validationSchema ?? Yup.object({}),
        onSubmit: (values, { resetForm }) => {
            const cleanedCompanyCollection = values.contact_company_collection.filter(
                (obj) => !Object.values(obj).some((value) => value === undefined)
            )

            const cleanedValues = {
                ...values,
                contact_company_collection: cleanedCompanyCollection,
            }

            if (isEdit) {
                dispatch(updateActivity(cleanedValues))
                setEvent(null)
                resetForm()
            } else {
                dispatch(createActivity(cleanedValues))
                resetForm()
            }

            setAcType(null)
            toggle()
        },
    })

    const selectedActivityType = getIn(validation.values, activityFields.ACTIVITY_TYPE_ID)

    useEffect(() => {
        if (selectedActivityType !== acType) {
            const foundAc = activityTypes?.find((x) => x.id === parseInt(selectedActivityType))

            if (foundAc) {
                setEvent((prevState) => ({
                    ...prevState,
                    activityType: {
                        ...(prevState?.activityType ? event.activityType : {}),
                        id: validation.values.activity_type_id,
                    },
                    title: validation.values.title,
                    location: activity?.location ?? validation.values.location,
                    durationDay: validation.values.duration_day ? 1 : 0,
                    contact: {
                        ...(prevState?.contact ? event.contact : {}),
                        id: validation.values.contact_id,
                    },
                    activityAgent: validation.values.activity_agent_group?.map((x) => {
                        let eventAgent = prevState?.activityAgent?.find((a) => a.agent?.id?.toString() === x.agent_id?.toString())

                        return {
                            agent: {
                                ...(eventAgent ? eventAgent?.agent : {}),
                                id: x.agent_id,
                            },
                            notificationSend: x.notification_send,
                        }
                    }),
                    notes: validation.values.notes,
                    project: validation.values.project_id.map((x) => ({ id: x })),
                    property: validation.values.property_id.map((x) => ({ id: x })),
                    activityReminder: validation.values.activity_reminder_group?.map((x) => ({
                        reminderType: x.reminder_type,
                        reminderSend: x.reminder_send ? 1 : 0,
                    })),
                }))

                setAcType(foundAc?.activityCategory?.id)
            }
        }
    }, [selectedActivityType])

    const submitOtherEvent = () => {
        setShowEditForm(true)
    }

    /**
     * On calendar drop event
     */
    const onDrop = (event) => {
        const date = event['date']
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

        const currectDate = new Date()
        const currentHour = currectDate.getHours()
        const currentMin = currectDate.getMinutes()
        const currentSec = currectDate.getSeconds()
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec)

        const draggedEl = event.draggedEl
        const draggedElclass = draggedEl.className
        const draggedElActivityTypeId = draggedEl.getAttribute('data-activity-type-id')
        if (draggedEl.classList.contains('external-event') && draggedElclass.indexOf('fc-event-draggable') === -1) {
            setEvent({
                id: event.id,
                title: event.title,
                end: event.end,
                [activityResponseFields.TITLE]: draggedEl.innerText,
                [activityResponseFields.DATE_FROM]: dayjs(modifiedDate).format('YYYY-MM-DD'),
                [activityResponseFields.TIME_FROM]: dayjs(modifiedDate).format('HH:mm'),
                [activityResponseFields.TIME_TO]: dayjs().add(15, 'minute').format('HH:mm'),
                [activityResponseFields.DATE_TO]: dayjs(modifiedDate).format('YYYY-MM-DD'),
                [activityResponseFields.ACTIVITY_TYPE]: {
                    id: draggedElActivityTypeId,
                },
            })

            toggle()
        }
    }

    const eventContent = ({ event }) => {
        const eventContact = event?.extendedProps?.contact
        const eventInfo = event?.extendedProps
        let contactName = ''

        if (eventContact) {
            if (eventContact.contactType === 'company') {
                contactName = eventContact.contactCompany?.name
            } else {
                contactName = `${eventContact.contactPerson?.firstName} ${eventContact.contactPerson?.lastName}`
            }
        }

        return (
            <>
                {eventContact && (
                    <div className="fs-13 fw-light" style={{ whiteSpace: 'normal' }}>
                        {contactName}
                    </div>
                )}

                <div className="fw-medium fs-13" style={{ whiteSpace: 'normal' }}>
                    {event?.title}
                </div>
                <div className="fw-light fs-13" style={{ whiteSpace: 'normal' }}>
                    {eventInfo?.activityType?.name}
                </div>
                {eventInfo?.activityAgent.map((agent) => {
                    return (
                        <div key={agent.id} className="fw-light fs-13" style={{ whiteSpace: 'normal' }}>
                            <span>
                                {agent.agent.firstName} {agent.agent.lastName}
                            </span>
                        </div>
                    )
                })}
            </>
        )
    }

    // handle custom styles
    const eventDidMount = ({ event, view, el }) => {
        let eventType = view.type
        const newColor = event.extendedProps.activityType.color

        switch (eventType) {
            case 'dayGridDay': {
                const parentElement = el.parentElement
                parentElement.style.backgroundColor = newColor
                parentElement.style.marginBottom = '10px'
                break
            }
            case 'dayGridWeek':
            case 'dayGridMonth': {
                el.style.backgroundColor = newColor
                break
            }
            case 'listWeek': {
                el.style.backgroundColor = newColor
                el.style.color = 'black'
                break
            }
            default:
                break
        }
    }

    document.title = `${t('app.common.activities')} | Dimedia nekretnine CRM`

    return (
        <React.Fragment>
            <DeleteModal show={deleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setDeleteModal(false)} />
            <div className="page-content">
                <Container fluid>
                    <PageTitle title={t('app.common.activities')} />
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={3}>
                                    {user && user?.roles?.includes(USER_ROLES.ROLE_ADMIN) && (
                                        <AppPermissionsSwitcher permission="AllowedAgents" module={'activities'} agentOptions={agentOptions}>
                                            {({ allowedAgentOptions }) => (
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <div className="d-flex justify-content-between">
                                                                    <Label for="acitivity-agents-filter" id={`activity-agents-filterLbl`}>
                                                                        {t('activityCalendar.label')}
                                                                    </Label>
                                                                </div>
                                                                <Select
                                                                    onChange={(e) => {
                                                                        setSelectedAgents(e ? e.map((item) => item.value) : [])
                                                                    }}
                                                                    options={allowedAgentOptions}
                                                                    isMulti={true}
                                                                    name="activitiy-agents-filter"
                                                                    id="activity-agents-filter"
                                                                    value={allowedAgentOptions.filter(
                                                                        (option) => selectedAgents.indexOf(option.value) >= 0
                                                                    )}
                                                                    noOptionsMessage={() => t('form.common.select.noData')}
                                                                    placeholder=""
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            )}
                                        </AppPermissionsSwitcher>
                                    )}
                                    <Card className="card-h-100">
                                        <CardBody>
                                            <button className="btn btn-add w-100" id="btn-new-event" onClick={handleAddButton}>
                                                <i className="mdi mdi-plus"></i> {t('button.add')}
                                            </button>

                                            <ExternalEvents
                                                activityTypes={activityTypes}
                                                selectedActivityTypes={selectedActivityTypes}
                                                setSelectedActivityTypes={setSelectedActivityTypes}
                                                handleActivityTypeChange={handleActivityTypeChange}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col xl={9}>
                                    <Card className="card-h-100">
                                        <CardBody style={{ position: 'relative' }}>
                                            {fetchingActivities ? (
                                                <div className="activity-loader-container">
                                                    <Loader />
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            <FullCalendar
                                                plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin, listPlugin]}
                                                initialView="dayGridMonth"
                                                slotDuration={'00:15:00'}
                                                handleWindowResize={true}
                                                themeSystem="bootstrap"
                                                headerToolbar={{
                                                    left: 'prev,next today',
                                                    center: 'title',
                                                    right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
                                                }}
                                                locale={selectedLocale}
                                                events={activities}
                                                editable={true}
                                                droppable={true}
                                                selectable={true}
                                                dateClick={handleDateClick}
                                                eventClick={(args) => handleEventClick(args)}
                                                datesSet={handleDateSet}
                                                drop={onDrop}
                                                eventContent={eventContent}
                                                eventDidMount={eventDidMount}
                                                firstDay={1}
                                                fixedWeekCount={false}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <div style={{ clear: 'both' }}></div>

                            <ActivityModal
                                isEdit={isEdit}
                                modal={modal}
                                event={event}
                                toggle={toggle}
                                showEditForm={showEditForm}
                                inputs={inputs}
                                validation={validation}
                                submitOtherEvent={submitOtherEvent}
                                selectOptions={selectOptions}
                                toggleDeleteModal={() => setDeleteModal(true)}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ActivityCalendar
