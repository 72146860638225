import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AppPermissionsSwitcher from '../../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../../../Components/AppPermissionsSwitcher/constants/actions'
import TableCheckbox from '../../../../Components/Table/TableCheckbox'
import { getCheckboxProps } from '../../../../Components/Table/utils'
import TableImage from '../../../../Components/TableImage/TableImage'
import TableShowHideText from '../../../../Components/TableShowHideText/TableShowHideText'
import { projectResponseFields } from '../../../../common/response/project'
import { toFullURL } from '../../../../utils/files'
import { findEntityTitle, formatDateTime } from '../../../../utils/general'

const TableColumns = (onDelete, module) => {
    const { t } = useTranslation()
    const currentLang = localStorage.getItem('i18nextLng')

    const columns = useMemo(
        () => [
            {
                Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
                    const checkboxProps = getCheckboxProps(toggleRowSelected, isAllPageRowsSelected, page)

                    return <TableCheckbox {...checkboxProps} />
                },
                Cell: ({ row }) => {
                    return <TableCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.disabled} />
                },
                id: '#',
                tdClassName: 'text-center align-middle',
            },
            {
                Header: t('app.common.id'),
                id: projectResponseFields.ID,
                accessor: projectResponseFields.ID,
                filterable: true,
            },
            {
                Header: t('table.header.common.mainPhoto'),
                id: 'mainPhoto',
                accessor: (project) => {
                    if (project.mainPhoto) {
                        return <TableImage src={toFullURL(project.mainPhoto.id)} alt={project.mainPhoto.fileDescription} />
                    }

                    return null
                },
                filterable: false,
                width: 100,
            },
            {
                Header: t('app.common.status'),
                id: projectResponseFields.PROJECT_STATUS,
                accessor: `${projectResponseFields.PROJECT_STATUS}.name`,
                filterable: true,
            },
            {
                Header: t('app.common.project.title'),
                id: projectResponseFields.PROJECT_TITLE,
                accessor: (project) => findEntityTitle(project, projectResponseFields.PROJECT_TITLE),
                filterable: false,
                Cell: (item, i) => (
                    <TableShowHideText
                        key={i}
                        text={item.row.original?.translations[currentLang]?.projectTitle}
                        rowId={item.cell.row.id}
                        numberOfRows={item.rows.length}
                    />
                ),

                minWidth: 200,
            },
            {
                Header: t('app.common.cityMunicipality'),
                id: projectResponseFields.CITY,
                accessor: `${projectResponseFields.CITY}.name`,
                filterable: true,
            },
            {
                Header: t('app.common.widerArea'),
                filterable: true,
                id: projectResponseFields.WIDER_AREA,
                accessor: `${projectResponseFields.WIDER_AREA}.name`,
                minWidth: 150,
            },
            {
                Header: t('app.common.quarter'),
                filterable: true,
                id: projectResponseFields.QUARTER,
                accessor: `${projectResponseFields.QUARTER}.name`,
            },
            {
                Header: t('app.common.agent'),
                id: projectResponseFields.AGENT,
                Cell: (item) => (
                    <>
                        {item.row.original?.[projectResponseFields.AGENT]?.[0].firstName}{' '}
                        {item.row.original?.[projectResponseFields.AGENT]?.[0].lastName}
                    </>
                ),
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.contractDate'),
                id: projectResponseFields.CONTRACT_DATE,
                Cell: (item) => formatDateTime(item.row.original?.[projectResponseFields.CONTRACT_DATE]),
                filterable: true,
            },
            {
                Header: t('app.common.contractNumber'),
                accessor: projectResponseFields.CONTRACT_NO,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('app.common.project.type'),
                id: projectResponseFields.PROJECT_TYPE,
                accessor: `${projectResponseFields.PROJECT_TYPE}.name`,
                filterable: true,
            },
            {
                Header: t('app.common.project.endDate'),
                id: projectResponseFields.PROJECT_END_DATE,
                Cell: (item) => formatDateTime(item.row.original?.[projectResponseFields.PROJECT_END_DATE]),
                filterable: true,
            },
            {
                Header: t('app.common.project.developmentStage'),
                id: projectResponseFields.PROJECT_DEVELOPMENT_STAGE,
                accessor: `${projectResponseFields.PROJECT_DEVELOPMENT_STAGE}.name`,
                filterable: true,
            },
            {
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    const itemAgentId = cellProps.row.original.agent?.[0].id

                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                            <li className="list-inline-item">
                                <Link title={t('button.view')} to={`/projects/${cellProps.row.original.id}`} className="text-info d-inline-block">
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </Link>
                            </li>

                            <AppPermissionsSwitcher permission="ModulePermissions" itemAgentId={itemAgentId} module={module} action={action.edit}>
                                {({ hasModulePermission }) =>
                                    hasModulePermission && (
                                        <>
                                            <li className="list-inline-item edit">
                                                <Link
                                                    title={t('button.edit')}
                                                    to={`/projects/${cellProps.row.original.id}/edit`}
                                                    className="text-info d-inline-block edit-item-btn"
                                                >
                                                    <i className="mdi mdi-pencil-outline fs-16"></i>
                                                </Link>
                                            </li>
                                            <li className="list-inline-item edit">
                                                <Link
                                                    title={t('button.delete')}
                                                    to="#"
                                                    className="text-danger d-inline-block remove-item-btn"
                                                    onClick={() => onDelete(cellProps.row.original.id)}
                                                >
                                                    <i className="mdi mdi-trash-can-outline fs-16"></i>
                                                </Link>
                                            </li>
                                        </>
                                    )
                                }
                            </AppPermissionsSwitcher>
                        </ul>
                    )
                },
            },
        ],
        [onDelete, module, t]
    )

    return columns
}

export default TableColumns
