import axios from 'axios'
import { delay, put, select } from 'redux-saga/effects'
import { API_URL } from '../common/constants'
import { authenticateExpired, authenticateFailure, selectAccessToken, selectIsAuthenticated, selectIsRefreshing } from './auth'

export function* apiRequest(url, options) {
    const headers = {}
    const accessToken = yield select(selectAccessToken)
    const isRefreshing = yield select(selectIsRefreshing)
    const isAuthenticated = yield select(selectIsAuthenticated)

    if (isRefreshing) {
        yield delay(200)
        return yield apiRequest(url, options)
    }

    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`
        headers.locale = localStorage.getItem('i18nextLng')
    }

    try {
        const response = yield axios({
            url: `${API_URL}${url}`,
            headers,
            ...options,
        })

        return response
    } catch (error) {
        yield handleRequestErrors(error, isAuthenticated, url, options)
    }
}

function* handleRequestErrors(error, isAuthenticated, url, options) {
    const { message } = error.response?.data || {}
    const { status } = error.response || {}

    if (!isAuthenticated || status !== 401) {
        console.error('error', error.response)
        throw error
    }

    switch (true) {
        // authenticate user again if jwt token expires
        case message === 'Expired JWT Token':
            yield put(authenticateExpired())
            return yield apiRequest(url, options)
        // logout user if token is invalid
        case message === 'Invalid JWT Token':
            return yield put(authenticateFailure())
        default:
            return null
    }
}
