import { combineActions, handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'
import {
    createContactFailure,
    createContactRequest,
    createContactSuccess,
    deleteContactFailure,
    deleteContactRequest,
    fetchContactFailure,
    fetchContactRequest,
    fetchContactsAsyncInputContactFailure,
    fetchContactsAsyncInputContactRequest,
    fetchContactsAsyncInputContactSuccess,
    fetchContactsFailure,
    fetchContactsRequest,
    fetchContactsSuccess,
    fetchContactSuccess,
    updateContactFailure,
    updateContactRequest,
    updateContactSuccess,
    deleteContactSuccess,
    queContactDocumentSuccess,
    uploadContactDocumentSuccess,
    uploadContactDocumentFailure,
    clearQueContactDocument,
    fetchContactDocumentsRequest,
    fetchContactDocumentsSuccess,
    fetchContactDocumentsFailure,
    deleteContactDocumentSuccess,
    syncContactDocumentSuccess,
    deleteContactMultipleRequest,
    deleteContactMultipleSuccess,
    deleteContactMultipleFailure,
    resetPageContact,
    updateContactsAgent,
} from './actions'

export const initialState = {
    items: {},
    item: {},
    itemdocument: {},
    profile: {},
    count: 0,
    itemdocumentcount: 0,
    fetching: true,
    fetchingDetails: false,
    fetchingProfile: false,
    fetchingDocuments: false,
    submitting: false,
    error: null,
    errorDetails: null,
    errorProfile: null,
    asyncFetching: false,
    documentque: {},
    pagination: {},
    links: {},
}

export default handleActions(
    {
        [fetchContactsRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchContactsSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            items: payload?.items,
            pagination: payload?.pagination,
            fetching: false,
        }),
        [fetchContactsFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [fetchContactRequest]: (state) => ({
            ...state,
            fetchingDetails: true,
            errorDetails: null,
        }),
        [fetchContactSuccess]: (state, { payload }) => ({
            ...state,
            item: {
                ...payload.item,
                profiles: payload?.contactProfile?.reduce((acc, item) => {
                    // let parsedItem = JSON.parse(item);
                    return { ...acc, [item.id]: item }
                }, {}),
            },
            fetchingDetails: false,
            links: payload.links,
        }),
        [fetchContactFailure]: (state, { payload }) => ({
            ...state,
            errorDetails: payload,
            fetchingDetails: false,
        }),

        [fetchContactDocumentsRequest]: (state) => ({
            ...state,
            fetchingDocuments: true,
            error: null,
        }),
        [fetchContactDocumentsSuccess]: (state, { payload }) => ({
            ...state,
            itemdocumentcount: payload?.count ?? 0,
            itemdocument: payload?.items?.reduce((acc, item) => {
                // let parsedItem = JSON.parse(item);
                return { ...acc, [item.id]: item }
            }, {}),
            fetchingDocuments: false,
        }),
        [fetchContactDocumentsFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingDocuments: false,
        }),
        [combineActions(updateContactRequest, createContactRequest)]: (state) => ({
            ...state,
            submitting: true,
        }),
        [combineActions(updateContactSuccess, createContactSuccess)]: (state) => ({
            ...state,
            submitting: false,
        }),
        [combineActions(updateContactFailure, createContactFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload,
        }),
        [fetchContactsAsyncInputContactRequest]: (state) => ({
            ...state,
            asyncFetching: true,
            error: null,
        }),
        [fetchContactsAsyncInputContactSuccess]: (state) => ({
            ...state,
            // asyncItems: payload?.items?.reduce((acc, item) => {
            //     // let parsedItem = JSON.parse(item);
            //     return { ...acc, [item.id]: item }}, {}),
            asyncFetching: false,
        }),
        [fetchContactsAsyncInputContactFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            asyncFetching: false,
        }),

        [deleteContactRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteContactSuccess]: (state) => {
            return {
                ...state,
                deleting: false,
                count: state.count - 1,
            }
        },
        [deleteContactFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [deleteContactMultipleRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteContactMultipleSuccess]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                count: state.count - payload?.length,
            }
        },
        [deleteContactMultipleFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [queContactDocumentSuccess]: (state, { payload }) => {
            // console.log('que payload', payload)
            return {
                ...state,
                documentque: {
                    ...payload?.reduce((acc, item) => {
                        // let parsedItem = JSON.parse(item);
                        let id = uuid()
                        return {
                            ...acc,
                            [id]: Object.assign(item, {
                                id: '',
                                tmpId: id,
                                preview: URL.createObjectURL(item),
                                // formattedSize: formatBytes(file.size),
                                queue: true,
                                uploading: false,
                            }),
                        }
                    }, {}),
                },
            }
        },
        [uploadContactDocumentSuccess]: (state, { payload }) => {
            // console.log('uploaded reducer', payload)
            return {
                ...state,
                documentque: {
                    ...state.photoque,
                    [payload.id]: Object.assign(payload, {
                        uploading: false,
                    }),
                },
            }
        },
        [uploadContactDocumentFailure]: (state, { payload }) => {
            return {
                ...state,
                documentque: {
                    ...state.photoque,
                    [payload?.file?.id]: Object.assign(payload?.file, {
                        uploading: false,
                        error: true,
                    }),
                },
            }
        },
        [clearQueContactDocument]: (state) => {
            return {
                ...state,
                documentque: {},
            }
        },
        [deleteContactDocumentSuccess]: (state, { payload }) => {
            const items = { ...state.itemdocument }
            // console.log('items', items)
            delete items[payload]
            return {
                ...state,
                deleting: false,
                itemdocument: items,
                itemdocumentcount: state.itemdocumentcount - 1,
            }
        },
        [syncContactDocumentSuccess]: (state, { payload }) => {
            const items = { ...state.itemdocument }
            // console.log('items', items)
            items[payload].fileSync = !items[payload].fileSync

            return {
                ...state,
                itemdocument: items,
            }
        },
        [resetPageContact]: (state) => ({
            ...state,
            pagination: { ...state.pagination, currentPage: 1 },
        }),
        [updateContactsAgent]: (state, { payload }) => {
            const { agent, selectedIds } = payload
            const updatedContacts = state.items.map((item) => (selectedIds.includes(item.id) ? { ...item, agent: agent } : item))

            return {
                ...state,
                items: updatedContacts,
            }
        },
    },
    initialState
)
