import { put, takeLatest } from 'redux-saga/effects'
import { fetchQuickSearch, fetchQuickSearchRequest, fetchQuickSearchSuccess } from './actions'

export function* fetchQuickSearchSaga({ payload }) {
    yield put(fetchQuickSearchRequest())

    yield put(
        fetchQuickSearchSuccess({
            criteria: {
                contactCriteria: payload.searchProps,
                companyCriteria: payload.searchProps,
                propertyCriteria: payload.searchProps,
                projectCriteria: payload.searchProps,
            }
        })
    )
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchQuickSearch}`, fetchQuickSearchSaga)
}
