import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import responseFields from '../../../common/constants/responseFields'
import { projectResponseFields } from '../../../common/response/project'
import { propertyResponseFields } from '../../../common/response/property'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../store/settings'
import { toFullURL } from '../../../utils/files'
import { findEntityTitle, formatDateTime, formatNumberWithTwoDecimals } from '../../../utils/general'
import { getOwnerFullName, renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../../utils/property'
import TableImage from '../../TableImage/TableImage'

const TableColumns = (onDelete) => {
    const { t } = useTranslation()

    const { currency, areaUnit } = useSelector((state) => {
        return {
            currency: selectApplicationCurrency(state),
            areaUnit: selectApplicationAreaUnit(state),
        }
    })

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                id: 'id',
                accessor: 'id',
                filterable: true,
            },
            {
                Header: t('table.header.common.mainPhoto'),
                filterable: false,
                id: 'propertyPhoto',
                accessor: (item) => {
                    const currentLang = localStorage.getItem('i18nextLng')
                    const fileDescription = item?.mainPhoto?.translations?.[currentLang]?.fileDescription ?? ''

                    if (item?.mainPhoto) {
                        return <TableImage src={toFullURL(item?.mainPhoto?.id)} alt={fileDescription} />
                    }

                    return null
                },
            },
            {
                Header: t('app.common.agencyId'),
                filterable: true,
                accessor: propertyResponseFields.INTERNAL_ID,
                Cell: (item) => {
                    const agencyId = item.row.original.agency.id
                    return <div className="text-center">{agencyId}</div>
                },
            },
            {
                Header: t('app.common.service'),
                accessor: `${propertyResponseFields.PROPERTY_SERVICE}.name`,
                id: `${propertyResponseFields.PROPERTY_SERVICE}`,
                filterable: true,
            },
            {
                Header: t('app.common.status'),
                accessor: `${propertyResponseFields.PROPERTY_STATUS}.name`,
                id: `${propertyResponseFields.PROPERTY_STATUS}`,
                filterable: true,
            },
            {
                Header: t('app.common.type'),
                accessor: `${propertyResponseFields.PROPERTY_TYPE}.name`,
                id: `${propertyResponseFields.PROPERTY_TYPE}`,
                filterable: true,
            },
            {
                Header: t('table.header.properties.subtype'),
                accessor: `${propertyResponseFields.PROPERTY_SUB_TYPE}.name`,
                id: `${propertyResponseFields.PROPERTY_SUB_TYPE}`,
                filterable: false,
            },
            {
                Header: t('app.common.country'),
                accessor: `${propertyResponseFields.COUNTRY}.name`,
                id: `${propertyResponseFields.COUNTRY}`,
                filterable: true,
            },
            {
                Header: t('app.common.county'),
                accessor: `${propertyResponseFields.COUNTY}.name`,
                id: `${propertyResponseFields.COUNTY}`,
                filterable: true,
            },
            {
                Header: t('app.common.city'),
                accessor: `${propertyResponseFields.CITY}.name`,
                id: `${propertyResponseFields.CITY}`,
                filterable: true,
            },
            {
                Header: t('app.common.widerArea'),
                accessor: `${propertyResponseFields.WIDER_AREA}.name`,
                id: `${propertyResponseFields.WIDER_AREA}`,
                filterable: true,
            },
            {
                Header: t('app.common.quarter'),
                accessor: `${propertyResponseFields.QUARTER}.name`,
                id: `${propertyResponseFields.QUARTER}`,
                filterable: true,
            },
            {
                Header: t('app.common.street'),
                accessor: `${propertyResponseFields.STREET}`,
                filterable: true,
            },
            {
                Header: t('app.common.streetNumber'),
                accessor: `${propertyResponseFields.STREET_NUMBER}`,
                filterable: true,
            },
            {
                Header: t('app.common.owner'),
                accessor: (property) => getOwnerFullName(property[propertyResponseFields.OWNER]),
                id: `${propertyResponseFields.OWNER}`,
                filterable: true,
            },
            {
                Header: t('app.common.price', { currency: renderCurrency(currency) }),
                id: `${propertyResponseFields.PRICE}`,
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.PRICE]
                            ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PRICE])
                            : null}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('table.header.properties.pricePerUnit', { currencyPerUnit: renderCurrencyWithUnit(currency, areaUnit) }),
                id: `${propertyResponseFields.PRICE_M2}`,
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.PRICE_M2]
                            ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PRICE_M2])
                            : null}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('app.common.area', { areaUnit: renderAreaUnit(areaUnit) }),
                id: propertyResponseFields.AREA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.AREA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.floorNumber'),
                id: propertyResponseFields.FLOOR_NO,
                Cell: (property) => {
                    const { original: propertyOriginal } = property.row

                    if (!propertyOriginal) {
                        return null
                    }

                    return (
                        <>
                            {propertyOriginal[propertyResponseFields.PROPERTY_FLOOR_POSITION]?.length > 0
                                ? propertyOriginal[propertyResponseFields.PROPERTY_FLOOR_POSITION][0].name
                                : propertyOriginal[propertyResponseFields.FLOOR_NO]
                                ? propertyOriginal[propertyResponseFields.FLOOR_NO]
                                : '-'}
                            {propertyOriginal[propertyResponseFields.FLOORS_TOTAL_NO]
                                ? `/${propertyOriginal[propertyResponseFields.FLOORS_TOTAL_NO]}`
                                : '/-'}
                        </>
                    )
                },
            },
            {
                Header: t('app.common.property.numberOfRooms'),
                accessor: `${propertyResponseFields.PROPERTY_ROOMS_NO}.name`,
                filterable: true,
            },
            {
                Header: t('app.common.bedrooms'),
                accessor: `${propertyResponseFields.BEDROOMS_NO}`,
                filterable: true,
            },
            {
                Header: t('app.common.agent'),
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.AGENT]?.[0]?.firstName}{' '}
                        {item.row.original?.[propertyResponseFields.AGENT]?.[0]?.lastName}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('table.header.properties.maxConstructionArea', { areaUnit: renderAreaUnit(areaUnit) }),
                id: `${propertyResponseFields.MAX_CONTRUCTION_AREA}`,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.MAX_CONTRUCTION_AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.MAX_CONTRUCTION_AREA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.property.seaDistance'),
                id: propertyResponseFields.DISTANCE_SEA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.DISTANCE_SEA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.DISTANCE_SEA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.property.yard'),
                id: propertyResponseFields.AREA_GARDEN,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.AREA_GARDEN]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.AREA_GARDEN])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.property.areaPlot'),
                id: propertyResponseFields.PLOT_AREA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.PLOT_AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PLOT_AREA])
                        : null,
                filterable: true,
            },
            {
                Header: t('table.header.properties.agentMobile'),
                id: 'agentMobileNumber',
                accessor: (property) => property[propertyResponseFields.AGENT]?.[0]?.mobile,
                filterable: true,
            },
            {
                Header: t('app.common.heating'),
                id: `${propertyResponseFields.PROPERTY_HEATING}`,
                Cell: (property) => (
                    <>
                        {property.row.original?.[propertyResponseFields.PROPERTY_HEATING]?.map((item) => (
                            <div key={item.id}>{item.name}</div>
                        ))}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('app.common.web'),
                id: 'websiteTitle',
                accessor: (property) => {
                    if (property.propertyWebsite?.[0]?.visibility === 1) {
                        return <i className="mdi mdi-check fs-4" />
                    }

                    return null
                },
                filterable: false,
            },
            {
                Header: t('table.header.properties.elevator'),
                id: propertyResponseFields.PROPERTY_EQUIPMENT,
                accessor: (property) => {
                    const elevator = property[propertyResponseFields.PROPERTY_EQUIPMENT]?.find(({ name }) => name.toLowerCase() === 'lift')

                    if (elevator) {
                        return <i className="mdi mdi-check fs-4" />
                    }

                    return null
                },
                filterable: false,
            },
            {
                Header: t('app.common.property.constructionYear'),
                accessor: `${propertyResponseFields.CONSTRUCTION_YEAR}`,
                filterable: true,
            },
            {
                Header: t('app.common.property.availableFrom'),
                accessor: `${propertyResponseFields.AVAILABLE_FROM_DATE}`,
                Cell: (item) => formatDateTime(item.row.original?.[propertyResponseFields.AVAILABLE_FROM_DATE]),
                filterable: true,
            },
            {
                Header: t('app.common.offerSource'),
                accessor: `${responseFields.PROPERTY_SUPPLY_SOURCE}.name`,
                id: `${responseFields.PROPERTY_SUPPLY_SOURCE}_name`,
                filterable: true,
            },
            {
                Header: t('table.header.properties.receiptDate'),
                id: `${propertyResponseFields.PROPERTY_RECEIPT_DATE}`,
                filterable: true,
                Cell: (item) => formatDateTime(item.row.original?.[propertyResponseFields.PROPERTY_RECEIPT_DATE]),
            },
            {
                Header: t('app.common.contractNumber'),
                accessor: `${propertyResponseFields.CONTRACT_NO}`,
                filterable: true,
            },
            {
                Header: t('table.header.properties.ownerPhoneNumber1'),
                id: `${propertyResponseFields.OWNER}_phone1`,
                accessor: (property) => property[propertyResponseFields.AGENT]?.[0]?.phone,
                filterable: true,
            },
            {
                Header: t('table.header.properties.ownerPhoneNumber2'),
                id: `${propertyResponseFields.OWNER}_phone2`,
                accessor: (property) => property[propertyResponseFields.AGENT]?.[1]?.phone,
                filterable: true,
            },
            {
                Header: t('app.common.project.label'),
                accessor: (property) => findEntityTitle(property.project, projectResponseFields.PROJECT_TITLE),
                filterable: true,
            },
            {
                Header: t('app.common.updated'),
                accessor: `${propertyResponseFields.UPDATED}`,
                filterable: true,
            },
            {
                Header: t('app.common.property.designation'),
                accessor: propertyResponseFields.PROPERTY_LABEL,
                filterable: false,
            },
            {
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                            <li className="list-inline-item edit">
                                <Link
                                    title={t('button.edit')}
                                    to={`/properties/${cellProps.row.original.id}/edit`}
                                    className="text-info d-inline-block edit-item-btn"
                                >
                                    <i className="mdi mdi-pencil-outline fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link title={t('button.view')} to={`/properties/${cellProps.row.original.id}`} className="text-info d-inline-block">
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Button
                                    title={t('button.delete')}
                                    color="link"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => {
                                        const propertyId = cellProps.row.original.id
                                        onDelete(propertyId)
                                    }}
                                >
                                    <i className="mdi mdi-trash-can-outline fs-16"></i>
                                </Button>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [onDelete, areaUnit, currency, t]
    )

    return columns
}

export default TableColumns
