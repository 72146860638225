import { FormikProvider, getIn, useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Container, Form, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import FormActionButtons from '../../../Components/Common/FormActionButtons'
import PageTitle from '../../../Components/Common/PageTitle'
import PropertyFormGeneralTab, {
    PropertyFormGeneralTabMain,
    PropertyFormGeneralTabSide,
} from '../../../Components/PropertyFormGeneralTab/PropertyFormGeneralTab'
import {
    useMapFormConfig,
    usePropertyExportFormConfig,
    useRealEstateDescriptionFormConfig,
    useRealEstateGeneralFormConfig,
    useRealEstateGeneralFormSelectOptions,
} from '../../../Components/Hooks/FormHooks'
import { realEstateGeneralFields } from '../../../common/forms/generalRealEstate/fields'

import { useTranslation } from 'react-i18next'
import { setPropertyStatus } from '../../../Components/AppPermissionsSwitcher/utils/propertyStatus'
import Loader from '../../../Components/Common/Loader'
import MapFormTab, { MapFormTabMain, MapFormTabSide } from '../../../Components/MapFormTab/MapFormTab'
import FormValidationErrorModal from '../../../Components/Modals/FormValidationErrorModal'
import PropertyFormDescriptionTab, {
    PropertyFormDescriptionTabMain,
    PropertyFormDescriptionTabSide,
} from '../../../Components/PropertyFormDescriptionTab/PropertyFormDescriptionTab'
import PropertyFormExportTab, {
    PropertyFormExportTabMain,
    PropertyFormExportTabSide,
} from '../../../Components/PropertyFormExportTab/PropertyFormExportTab'
import { propertyResponseFields } from '../../../common/response/property'
import { selectCurrentUser } from '../../../store/auth/selectors'
import properties from '../../../api/properties/hooks'
import { propertyGeneralFormTabs } from '../constants'
import { RouteParams } from '../../../api/globalTypes'
import {
    DescriptionValidationConfig,
    ExportValidationConfig,
    FormikConfig,
    MapValidationConfig,
    PropertyCreateTranslations,
} from './PropertyCreate.types'
import { changeTranslationObject } from '../../../utils/general'
import { Property } from '../../../api/properties/types'

const PropertyCreate = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const { id } = useParams<RouteParams>()

    const isClone = typeof id === 'string'
    const tabs = propertyGeneralFormTabs(false, isClone)
    const refreshToken = localStorage.getItem('refresh_token')

    const [activeTab, setActiveTab] = useState(tabs[0].name)
    const [realEstateType, setRealEstateType] = useState('')
    const [realEstateService, setRealEstateService] = useState('')
    const [isErrorModalActive, setIsErrorModalActive] = useState(false)
    const sideTabData = useRef<Property | null>(null)
    const breakUseEffectLoop = useRef(0)

    const { isLoading, data } = id ? properties.GetProperty.useQuery(id, refreshToken) : { isLoading: null, data: null }
    const { mutate: createUpdateMutate } = properties.CreateUpdateProperty.useMutation(id, false)

    const property = !isLoading
        ? {
              ...data?.item,
              id: null,
              [propertyResponseFields.INTERNAL_ID]: null,
          }
        : null

    const { agent } = useSelector((state) => ({
        agent: selectCurrentUser(state),
    }))

    const { defaultCountry, defaultCounty, defaultCity, agentPermissions } = agent

    const propertyFormGeneralTabLocationData = {
        country: defaultCountry,
        county: defaultCounty,
        city: defaultCity,
    }

    const { formInputs, formikConfig } = useRealEstateGeneralFormConfig(
        isClone ? 1 : 0,
        isClone ? property?.propertyType?.id : realEstateType,
        isClone ? property?.propertyService?.id : realEstateService,
        isClone ? property : null
    )
    const { descriptionInputs, descriptionValidationConfig } = useRealEstateDescriptionFormConfig(isClone ? property : null)
    const { mapInputs, mapValidationConfig } = useMapFormConfig(isClone ? property : null)
    const { exportInputs, exportValidationConfig } = usePropertyExportFormConfig(isClone ? property : null)
    const formSelectOptions = useRealEstateGeneralFormSelectOptions()

    const handleTabChange = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: (descriptionValidationConfig as unknown as DescriptionValidationConfig).initialValues ?? {},
            general:
                (isClone
                    ? {
                          ...(formikConfig as unknown as FormikConfig).initialValues,
                          [realEstateGeneralFields.PRICE_OLD]: '',
                      }
                    : (formikConfig as unknown as FormikConfig).initialValues) ?? {},
            ...(isClone
                ? {
                      map: (mapValidationConfig as unknown as MapValidationConfig).initialValues ?? {},
                      export: (exportValidationConfig as unknown as ExportValidationConfig).initialValues ?? {},
                  }
                : {}),
        },
        validationSchema: Yup.object({
            general: (formikConfig as unknown as FormikConfig).validationSchema ?? Yup.object({}),
            description: (descriptionValidationConfig as unknown as DescriptionValidationConfig).validationSchema ?? Yup.object({}),
            map: isClone ? (mapValidationConfig as unknown as MapValidationConfig).validationSchema : Yup.object({}),
            export: isClone ? (exportValidationConfig as unknown as ExportValidationConfig).validationSchema : Yup.object({}),
        }),
        onSubmit: (values) => {
            const generalValues = (formikConfig as unknown as FormikConfig).prepareModelForSubmit(values.general)
            const { defaultStatus } = setPropertyStatus({ agentPermissions, selectOptions: [], propertyStatus: null })

            const translations = changeTranslationObject(
                values.description,
                'property_title',
                'property_description'
            ) as unknown as PropertyCreateTranslations

            const submitValues = {
                ...generalValues,
                ...values.description,
                ...(isClone
                    ? {
                          ...values.map,
                          ...values.export,
                      }
                    : {}),
                translations,
                property_status_id: defaultStatus,
                agent_id: [values.general.agent_id, values.general.agent_id_secondary],
                [realEstateGeneralFields.CONSTRUCTION_COEFFICIENT]: values?.general.construction_coefficient
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE]: values?.general.price?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_INITIAL]: values?.general.priceInitial?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_CLIENT]: values?.general.priceClient?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_M2]: values?.general.price_m2?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_OLD]: values?.general.priceOld?.toString().replace(/\./g, '').replace(/,/g, '.'),
            }
            delete submitValues.id
            delete submitValues.agent_id_secondary
            submitValues.agent_id = submitValues.agent_id.filter(Boolean)

            createUpdateMutate(submitValues)
        },
    })
    const realEstateTypeValue = getIn(validation.values?.general, realEstateGeneralFields.PROPERTY_TYPE_ID)
    const realEstateServiceValue = getIn(validation.values?.general, realEstateGeneralFields.PROPERTY_SERVICE_ID)

    // This is temporary fix. Side tab and main tab needs refactoring.
    useEffect(() => {
        if (realEstateType === '' || realEstateService === '') {
            sideTabData.current = validation.values.general
        }
        if (
            realEstateType !== '' &&
            realEstateType !== undefined &&
            realEstateService !== '' &&
            realEstateService !== undefined &&
            sideTabData.current !== null &&
            breakUseEffectLoop.current < 5
        ) {
            validation.setFormikState((prevState) => ({
                ...prevState,
                values: {
                    ...prevState.values,
                    general: {
                        ...prevState.values.general,
                        ...sideTabData.current,
                    },
                },
            }))
            breakUseEffectLoop.current++
        }
    }, [realEstateService, realEstateType, validation, validation.values.general])

    useEffect(() => {
        if (realEstateTypeValue !== realEstateType) {
            setRealEstateType(realEstateTypeValue)
            breakUseEffectLoop.current = 0
            sideTabData.current = validation.values.general
        }
    }, [realEstateTypeValue, realEstateType, validation.values.general])

    useEffect(() => {
        if (realEstateServiceValue !== realEstateService) {
            setRealEstateService(realEstateServiceValue)
            breakUseEffectLoop.current = 0
            sideTabData.current = validation.values.general
        }
    }, [realEstateServiceValue, realEstateService, validation.values.general])

    useEffect(() => {
        if (!validation.isValid && !validation.isValidating && validation.isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [validation.isValid, validation.isValidating, validation.isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push('/properties')} />

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    if (isClone && isLoading) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle title={t('form.property.create.title')}>{renderFormButtons()}</PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <PropertyFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={formInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                    realEstateType={realEstateType}
                                    realEstateService={realEstateService}
                                    locationData={propertyFormGeneralTabLocationData}
                                    action={'create'}
                                >
                                    <PropertyFormGeneralTabSide />
                                    <PropertyFormGeneralTabMain />
                                </PropertyFormGeneralTab>
                            </TabPane>

                            <TabPane tabId="description">
                                <PropertyFormDescriptionTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={descriptionInputs}
                                    subform="description"
                                    formik={validation}
                                    realEstateType={realEstateType}
                                    realEstateService={realEstateService}
                                >
                                    <PropertyFormDescriptionTabSide />
                                    <PropertyFormDescriptionTabMain />
                                </PropertyFormDescriptionTab>
                            </TabPane>

                            {isClone && (
                                <>
                                    <TabPane tabId="map">
                                        <MapFormTab
                                            tabs={tabs}
                                            activeTab={activeTab}
                                            handleTabChange={handleTabChange}
                                            inputs={mapInputs}
                                            formik={validation}
                                        >
                                            <MapFormTabSide />
                                            <MapFormTabMain />
                                        </MapFormTab>
                                    </TabPane>
                                    <TabPane tabId="export">
                                        <PropertyFormExportTab
                                            tabs={tabs}
                                            activeTab={activeTab}
                                            handleTabChange={handleTabChange}
                                            subform="export"
                                            formik={validation}
                                        >
                                            <PropertyFormExportTabSide />
                                            <PropertyFormExportTabMain />
                                        </PropertyFormExportTab>
                                    </TabPane>
                                </>
                            )}
                        </TabContent>
                    </Form>
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal
                    validation={validation}
                    inputs={[formInputs, descriptionInputs, mapInputs, exportInputs]}
                    onClose={() => toggleErrorModal()}
                    modalErrors={undefined}
                />
            )}
        </div>
    )
}

export default PropertyCreate
