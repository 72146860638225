import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const BackButton = ({ className }) => {
    const history = useHistory()
    const linkUrlArray = history.location.pathname.split('/')
    const { t } = useTranslation()

    const handleClick = () => {
        history.push(`/${linkUrlArray[1]}`, history.location.state)
    }

    return <a title={t('button.back')} onClick={handleClick} className={`btn btn-primary ${className} mdi mdi-undo-variant text-white`} />
}

export default BackButton
