import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ContactColumns = () => {
    const { t } = useTranslation()

    const columns = [
        {
            Header: t('app.common.id'),
            id: 'contact',
            Cell: ({ row }) => {
                return row.original?.contact?.id
            },
            filterable: true,
            minWidth: 50,
        },
        {
            Header: t('app.common.contact.nameAndSurname'),
            id: 'contactPersonName',
            Cell: ({ row }) => {
                const contactPerson = row.original?.contact?.contactPerson
                return `${contactPerson?.firstName ?? ''} ${contactPerson?.lastName ?? ''}`
            },
            filterable: true,
            minWidth: 235,
        },
        {
            Header: t('app.common.company.name'),
            id: 'contactCompanyName',
            Cell: ({ row }) => {
                const contact = row.original?.contact

                // contactPersonCompanyName - name of company related to person
                // contactCompanyName - name of standalone company
                const contactPersonCompanyName = contact?.contactPersonCompany?.[0]?.company?.contactCompany?.name ?? ''
                const contactCompanyName = contact?.contactCompany?.name ?? ''

                return contactPersonCompanyName || contactCompanyName
            },
            filterable: true,
            minWidth: 180,
        },
        {
            Header: t('app.common.contactPhoneNumber1'),
            id: 'contactPhone',
            Cell: ({ row }) => {
                const phoneNumber = row.original?.contact?.contactPhone?.[0]?.phoneNumber ?? ''
                return phoneNumber
            },
            filterable: true,
            minWidth: 135,
        },
        {
            Header: t('app.common.email.label'),
            id: 'contactEmail',
            Cell: ({ row }) => {
                const email = row.original?.contact?.contactEmail?.[0]?.email ?? ''
                return email
            },
            filterable: true,
            minWidth: 280,
        },
        {
            Header: t('app.common.agent'),
            id: 'agent',
            Cell: ({ row }) => {
                const agent = row.original?.contact?.agent
                return `${agent?.firstName} ${agent?.lastName}`
            },
            filterable: true,
            minWidth: 200,
        },
        {
            id: 'actions',
            thDivClass: 'text-center',
            Cell: ({ row }) => {
                const contactType = row.original?.contact?.contactType
                const urlBase = contactType === 'person' ? 'contacts' : 'companies'

                return (
                    <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                        <li className="list-inline-item edit">
                            <Link
                                title={t('button.view')}
                                to={`/${urlBase}/${row.original?.contact?.id}`}
                                className="text-info d-inline-block edit-item-btn"
                            >
                                <i className="mdi mdi-eye-outline fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                )
            },
            minWidth: 40,
        },
    ]

    return columns
}

export default ContactColumns
