import { setAllowedAgents } from './allowedAgents'
import { setAllowedPhotos } from './allowedPhotos'
import { setAllowedPermissionTabs } from './allowedTabs'
import { setChangeAgent } from './changeAgent'
import { setEditData } from './editData'
import { setGoogleMaps } from './googleMaps'
import { setModulePermissions } from './module'
import { setNavigation } from './navigation'
import { setPropertyStatus } from './propertyStatus'
import { setViewData } from './viewData'

const setEditPermissions = (isAgentOwnership, isAgentOffice, actionName, module, level, viewLevel) => {
    const isAffectedModule = ['contacts', 'companies'].includes(module)

    const permissions = {
        read_only: ['view'],
        read_write_delete: ['view', 'edit', 'delete'],
    }

    const allowedActions = permissions[level]

    if (isAgentOwnership) {
        return true
    }

    if (!allowedActions) {
        console.log(`Non existing edit level: ${level}`)
        return false
    }

    const hasEditPermissions = allowedActions.includes(actionName)

    // special case of edit permissions is applied for modules Contacts & Companies
    // since they are not filtered like Projects & Properties
    const contactsCompaniesEditPermissions = {
        all: hasEditPermissions,
        office: hasEditPermissions && isAgentOffice,
        own: false,
    }

    const hasContactsCompaniesEditPermissions = contactsCompaniesEditPermissions[viewLevel]

    return isAffectedModule ? hasContactsCompaniesEditPermissions : hasEditPermissions
}

// applied only for Contacts/Companies modules
const checkViewLevel = (permissionsArray, module) => {
    if (!module) return

    // module comes in plural
    const moduleMapping = {
        contacts: 'contact',
        companies: 'company',
    }

    const formattedModuleName = moduleMapping[module]

    const viewPermissions = ['module.contact.view', 'module.company.view']
    const permission = viewPermissions.find((p) => p.includes(formattedModuleName))

    return checkPermissionLevel(permissionsArray, permission)
}

// helper function for getAgentIdFromItem & getAgentOfficeIdFromItem
const getAgentData = (item, module, id) => {
    if (!module || !id) return null

    const isContactsOrCompanies = ['contacts', 'companies'].includes(module)
    const agent = item?.agent

    if (!agent) return null

    return { isContactsOrCompanies, agent }
}

export const checkPermissionLevel = (permissionsArray, permissionName) => {
    const permission = permissionsArray?.find((obj) => obj.permission.token === permissionName)
    return permission ? permission.permissionLevel.token : null
}

export const generatePermissionsData = (permissions, agentPermissions, isAgentOwnership, isAgentOffice) => {
    return permissions.reduce((acc, permission) => {
        const { ruleName, dataName } = permission
        const result = setPermissions(agentPermissions, ruleName, isAgentOwnership, isAgentOffice)

        acc = { ...acc, [dataName]: result }
        return acc
    }, {})
}

export const setPermissions = (agentPermissions, permission, isAgentOwnership, isAgentOffice, module, actionName = '') => {
    if (!agentPermissions) return

    const level = checkPermissionLevel(agentPermissions, permission)
    const viewLevel = checkViewLevel(agentPermissions, module)

    switch (level) {
        case 'all':
        case 'yes':
            return true
        case 'office':
            return isAgentOffice
        case 'no':
            return false
        case 'own':
            return isAgentOwnership
        case 'read_only':
        case 'read_write_delete':
            return setEditPermissions(isAgentOwnership, isAgentOffice, actionName, module, level, viewLevel)
        default:
            // uncomment line below if something is breaking to get more info
            // console.error(`Non existing permission level: ${level}`)
            return null
    }
}

// checks for agent ownership (is item created by current agent) over view, list, activity or document
export const handleAgentOwnership = (agentOwnershipId, currentAgentId, itemAgentId, activityAgentId, documentAgentId) => {
    const projectsPropertiesAgentViewCheck = Array.isArray(agentOwnershipId) && agentOwnershipId?.some((obj) => obj.id === currentAgentId)
    const contactsCompaniesAgentViewCheck = agentOwnershipId === currentAgentId
    const listAgentCheck = itemAgentId === currentAgentId
    const activityAgentCheck = activityAgentId === currentAgentId
    const documentAgentCheck = documentAgentId === currentAgentId

    return listAgentCheck || projectsPropertiesAgentViewCheck || contactsCompaniesAgentViewCheck || activityAgentCheck || documentAgentCheck
}

// checks for agent office (is item created by agent of same office) over view, list or document
export const handleAgentOffice = (agentOfficeId, currentOffice, itemAgentOfficeId) => {
    const projectsPropertiesOfficeViewCheck = Array.isArray(agentOfficeId) && agentOfficeId?.some((obj) => obj.id === currentOffice?.id)
    const contactsCompaniesOfficeViewCheck = agentOfficeId === currentOffice?.id
    const listOfficeCheck = itemAgentOfficeId === currentOffice?.id
    const documentOfficeCheck = itemAgentOfficeId === currentOffice?.id

    return listOfficeCheck || projectsPropertiesOfficeViewCheck || contactsCompaniesOfficeViewCheck || documentOfficeCheck
}

export const getAgentIdFromItem = (item, module, id) => {
    const data = getAgentData(item, module, id)

    if (!data) return null

    const { isContactsOrCompanies, agent } = data

    // Contact & Companies agent data is object, Projects & Properties it is array
    return isContactsOrCompanies ? agent?.id : agent?.map((item) => ({ id: item.id }))
}

export const getAgentOfficeIdFromItem = (item, module, id) => {
    const data = getAgentData(item, module, id)

    if (!data) return null

    const { isContactsOrCompanies, agent } = data

    return isContactsOrCompanies ? agent?.agencyOffice?.id : agent?.map((item) => ({ id: item.agencyOffice?.id }))
}

export {
    setAllowedAgents,
    setAllowedPermissionTabs,
    setAllowedPhotos,
    setChangeAgent,
    setEditData,
    setGoogleMaps,
    setModulePermissions,
    setNavigation,
    setPropertyStatus,
    setViewData,
}
