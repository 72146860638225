import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import properties from '../../../api/properties/hooks'
import TableContainer from '../../Table/TableContainer'
import OpportunityDropDown from '../OpportunityDropDown/OpportunityDropDown'

const OpportunityTable = (props) => {
    const { columns, url, tableHash } = props
    const { id } = useParams()

    const [opportunityParams, setOpportunityParams] = useState({})
    const totalPages = useRef()

    const refreshToken = localStorage.getItem('refresh_token')

    const {
        isLoading: fetchingOpportunity,
        data: opportunityData,
        isFetched,
    } = properties.GetOpportunity.useQuery({ id, url, ...opportunityParams }, refreshToken)

    const opportunity = !fetchingOpportunity ? opportunityData : null
    if (isFetched) totalPages.current = opportunity?.pagination?.totalPages

    const handleDataFetch = (page, limit, order) => {
        setOpportunityParams({ page, limit, order })
    }

    const tableActions = () => <OpportunityDropDown setOpportunityParams={setOpportunityParams} />

    return (
        <TableContainer
            tableActions={tableActions}
            columns={columns}
            data={opportunity?.items || []}
            isGlobalFilter={false}
            isAddUserList={false}
            isLoading={fetchingOpportunity}
            customPageSize={20}
            tableClass="dmn-table--property-documents"
            theadClass={'dmn-table-header'}
            totalCount={opportunity?.pagination?.totalItems}
            pageCount={totalPages.current}
            handleDataFetch={handleDataFetch}
            tableHash={tableHash}
        />
    )
}

export default OpportunityTable
