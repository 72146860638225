import QueryString from 'qs'
import { PageListParams } from '../globalTypes'
import { getSearchCriteriaAndOrderFromStore } from '../helpers'
import { apiReq } from '../index'
import { OpportunityListParams, Property } from './types'

export const getPropertyById = async (id: string) => {
    const response = await apiReq(`v1/property/${id}`, {
        params: getSearchCriteriaAndOrderFromStore('property'),
        paramsSerializer: (params: unknown) => {
            return QueryString.stringify(params)
        },
    })

    return response?.data
}

export const getProperties = async (payload: PageListParams) => {
    const response = await apiReq(`v1/property`, {
        params: payload,
        paramsSerializer: (params: PageListParams) => {
            return QueryString.stringify(params)
        },
    })

    return response?.data
}

export const getOpportunityById = async (params: OpportunityListParams) => {
    const { id, url, limit, order, page } = params
    const key = Object.keys(order)[0]
    const paramsLink = `criteria[property][0]=${id}&limit=${limit}&order[${key}]=${order[`${key}`]}&page=${page}`
    const paramsEncoded = QueryString.stringify(QueryString.parse(paramsLink))
    const apiUrl = `v1/${url}?${paramsEncoded}`

    const response = await apiReq(apiUrl)

    return response?.data
}

export const createUpdateProperty = async (data: Property, isEdit?: boolean) => {
    const url = isEdit ? `v1/property/${data.id}` : `v1/property`

    const response = await apiReq(url, {
        method: isEdit ? 'PUT' : 'POST',
        data: data,
    })

    if (response.status !== 200 && response.status !== 201) {
        throw new Error()
    }

    return response?.data
}

export const deletePropertyById = async (id: number) => {
    const response = await apiReq(`v1/property/${id}`, {
        method: 'DELETE',
    })

    if (response.status !== 200) {
        throw new Error()
    }

    return response?.data
}

export const deleteMultipleProperties = async (ids: number[]) => {
    const response = await apiReq(`v1/property`, {
        method: 'DELETE',
        data: {
            ids,
        },
    })

    if (response.status !== 200) {
        throw new Error()
    }

    return response?.data
}
