import QueryString from 'qs'
import { toast } from 'react-toastify'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import i18n from '../../i18n'
import { apiRequest } from '../api'
import {
    createActivity,
    createActivityFailure,
    createActivityRequest,
    createActivitySuccess,
    deleteActivity,
    deleteActivityRequest,
    deleteActivitySuccess,
    fetchActivities,
    fetchActivitiesFailure,
    fetchActivitiesRequest,
    fetchActivitiesSuccess,
    fetchActivity,
    fetchActivityFailure,
    fetchActivityRequest,
    fetchActivitySuccess,
    updateActivity,
    updateActivityFailure,
    updateActivitySuccess,
} from './actions'
import { invalidateQueryInTable } from '../../api/helpers'

export function* fetchActivitiesSaga({ payload }) {
    // console.log('payload', payload)
    yield put(fetchActivitiesRequest())
    const { contact, ...params } = payload
    let fetchUrl = 'v1/activity'
    if (contact) {
        fetchUrl = fetchUrl + `/contact/${contact}`
    }
    try {
        const response = yield apiRequest(`${fetchUrl}`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchActivitiesSuccess(response.data))
        // meta();
    } catch (error) {
        yield put(fetchActivitiesFailure(error))
    }
}

export function* fetchActivitySaga({ payload }) {
    yield put(fetchActivityRequest())
    try {
        const response = yield apiRequest(`v1/activity/${payload}`, {
            method: 'GET',
        })
        yield put(fetchActivitySuccess(response.data))
        // meta();
    } catch (error) {
        yield put(fetchActivityFailure(error))
    }
}

export function* createActivitySaga({ payload }) {
    const { contact_company_collection } = payload
    const contactId = contact_company_collection[0]?.contact_id
    const updatedCollection = contactId === undefined ? [] : contact_company_collection
    const updatedPayload = { ...payload, contact_company_collection: updatedCollection }

    yield put(createActivityRequest())
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest(`v1/activity`, {
            method: 'POST',
            data: updatedPayload,
        })
        const { id } = response.data
        let activityData = updatedPayload
        if (id) {
            const response = yield apiRequest(`v1/activity/${id}`, {
                method: 'GET',
            })

            activityData = response.data
        }

        yield put(createActivitySuccess(activityData))
        //         if (meta && meta.callback) {
        //  ();
        //
        invalidateQueryInTable('activity')
        invalidateQueryInTable('contactList')

        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(createActivityFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* updateActivitySaga({ payload, meta }) {
    const { id, ...data } = payload
    const progressToast = toast.loading(i18n.t('toast.loading'))

    try {
        yield apiRequest(`v1/activity/${id}`, {
            method: 'PUT',
            data: data,
        })

        // TODO optimize this (check if contact_id, property_id or project_id changed)
        // or maybe return updated activity from api

        const activityResponse = yield apiRequest(`v1/activity/${id}`, {
            method: 'GET',
        })
        payload = activityResponse.data

        yield put(updateActivitySuccess(payload))
        invalidateQueryInTable('activity')
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(updateActivityFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }

    if (meta && meta.callback) {
        meta.callback()
    }
}

export function* deleteActivitySaga({ payload, meta }) {
    yield put(deleteActivityRequest())
    try {
        yield apiRequest(`v1/activity/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteActivitySuccess(payload))
        invalidateQueryInTable('activity')
        if (meta && meta.callback) {
            meta.callback()
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateActivityFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchActivities}`, fetchActivitiesSaga)
    yield takeLatest(`${fetchActivity}`, fetchActivitySaga)
    yield takeEvery(`${createActivity}`, createActivitySaga)
    yield takeEvery(`${updateActivity}`, updateActivitySaga)
    yield takeEvery(`${deleteActivity}`, deleteActivitySaga)
}
