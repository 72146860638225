import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { queryClient } from '../..'
import i18n from '../../i18n'
import { DeleteMultipleResponse, PageListParams } from '../globalTypes'
import { invalidateQueryInTable } from '../helpers'
import { createUpdateProperty, deleteMultipleProperties, deletePropertyById, getOpportunityById, getProperties, getPropertyById } from './functions'
import { OpportunityActivity, OpportunityContact, OpportunityListParams, Property, PropertyData, PropertyDataList } from './types'

const properties = {
    GetProperty: {
        useQuery: (id: string, refreshToken: string | null, options?: UseQueryOptions<PropertyData, AxiosError>) =>
            useQuery({
                queryKey: ['properties', id, refreshToken],
                queryFn: () => getPropertyById(id),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    GetProperties: {
        useQuery: (payload: PageListParams, refreshToken: string | null, options?: UseQueryOptions<PropertyDataList, AxiosError>) =>
            useQuery({
                queryKey: [payload.page, payload.limit, payload.order, payload.criteria, 'propertiesList', refreshToken],
                queryFn: () => getProperties(payload),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    GetOpportunity: {
        useQuery: (
            params: OpportunityListParams,
            refreshToken: string | null,
            options?: UseQueryOptions<OpportunityActivity | OpportunityContact, AxiosError>
        ) =>
            useQuery({
                queryKey: [params, refreshToken],
                queryFn: () => getOpportunityById(params),
                // TODO: not showing fresh data when contact profile edit and return to opportunity table
                // staleTime: 1 * 60 * 1000,
                enabled: Boolean(params?.page && params?.limit && params?.order),
                ...options,
            }),
    },
    CreateUpdateProperty: {
        useMutation: (propertyId: string, isEdit?: boolean, options?: UseMutationOptions<Property, AxiosError, Property>) => {
            const toastId = isEdit ? propertyId : 'new'
            const history = useHistory()

            return useMutation({
                ...options,
                onMutate: () => {
                    toast.loading(i18n.t('toast.loading') as string, { toastId: toastId })
                },
                mutationFn: (data) => createUpdateProperty(data, isEdit),
                onSuccess: (data) => {
                    isEdit && queryClient.invalidateQueries({ queryKey: ['properties', propertyId] })
                    invalidateQueryInTable('propertiesList')
                    toast.update(toastId, {
                        render: i18n.t('toast.update.success') as string,
                        type: 'success',
                        autoClose: 3000,
                        isLoading: false,
                    })

                    !isEdit && history.push(`/properties/${data.id}/edit`)
                },
                onError: (error) => {
                    toast.update(toastId, {
                        render: i18n.t('toast.update.error') as string,
                        type: 'error',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    console.error(error)
                },
            })
        },
    },
    DeleteProperty: {
        useMutation: (options?: UseMutationOptions<Property, AxiosError, number>) => {
            const history = useHistory()
            return useMutation({
                ...options,
                mutationFn: (id) => {
                    toast.loading(i18n.t('toast.loading.delete') as string, { toastId: id.toString() })
                    return deletePropertyById(id)
                },
                onSuccess: (data) => {
                    invalidateQueryInTable('propertiesList')
                    toast.update(data.id?.toString() ?? '0', {
                        render: i18n.t('toast.update.delete.success') as string,
                        type: 'success',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    history.push(`/properties`)
                },
                onError: (error, id) => {
                    toast.update(id.toString(), {
                        render: i18n.t('toast.update.delete.error') as string,
                        type: 'error',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    console.error(error)
                },
            })
        },
    },
    DeleteMultipleProperty: {
        useMutation: (options?: UseMutationOptions<DeleteMultipleResponse, AxiosError, number[]>) =>
            useMutation({
                ...options,
                mutationFn: (idsArray) => {
                    const requestsToBeDeletedCount = idsArray.length
                    toast.loading(i18n.t('toast.loading.delete.properties.number', { requestsToBeDeletedCount }) as string, {
                        toastId: idsArray[0].toString(),
                    })
                    return deleteMultipleProperties(idsArray)
                },
                onSuccess: (data, idsArray) => {
                    const requestsToBeDeletedCount = idsArray?.length
                    const failedCount = data?.notFoundIds?.length
                    const successCount = data?.successfulDelete?.length

                    if (failedCount > 0) {
                        toast.update(idsArray[0].toString(), {
                            render: i18n.t('toast.loading.delete.properties.number.error', { failedCount, requestsToBeDeletedCount }) as string,
                            type: 'error',
                            isLoading: false,
                            autoClose: 3000,
                        })
                    }

                    if (successCount > 0) {
                        toast.update(idsArray[0].toString(), {
                            render: i18n.t('toast.loading.delete.properties.number.success', { successCount, requestsToBeDeletedCount }) as string,
                            type: 'success',
                            isLoading: false,
                            autoClose: 3000,
                        })
                        invalidateQueryInTable('propertiesList')
                    }
                },
                onError: (error, idsArray) => {
                    toast.update(idsArray[0].toString(), {
                        render: i18n.t('toast.update.delete.error') as string,
                        type: 'error',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    console.error(error)
                },
            }),
    },
}

export default properties
