import { CONTACT_TYPE } from '../constants'
import { contactResponseFields } from '../response/contact'
import { propertyResponseFields } from '../response/property'
import i18next from 'i18next'

export function getOwnersBuyerViewData(owners, isBuyer) {
    if (!owners) return []
    const { t } = i18next

    return owners.flatMap((owner) => {
        const addressStruct = [
            owner.street,
            owner.streetNumber,
            '<br>',
            owner.zipCode,
            owner.city ? `${owner.city},` : null,
            owner.contactCountry?.name,
        ]

        const address = addressStruct.every(Boolean) ? addressStruct.join(' ') : null

        const phones =
            owner[contactResponseFields.CONTACT_PHONE]?.map((phone, i) => ({
                id: 'phone',
                phoneId: phone.id,
                label: `${t('view.contact.general.phone')} ${i + 1}`,
                value: phone.phoneNumber,
            })) || []

        const emails =
            owner[contactResponseFields.CONTACT_EMAIL]?.map((email, i) => ({
                id: 'email',
                emailId: email.id,
                label: `${t('app.common.email.label')} ${i + 1}`,
                value: email.email,
            })) || []

        let ownerLabel
        let ownerValue
        let ownerId

        if (owner[contactResponseFields.CONTACT_TYPE] === CONTACT_TYPE.PERSON) {
            ownerId = owner?.id
            ownerLabel = isBuyer ? t('app.common.contact.nameAndSurname') : t('view.property.general.owner.firstLastName')
            ownerValue = `${owner[contactResponseFields.CONTACT_PERSON]?.firstName} ${owner[contactResponseFields.CONTACT_PERSON]?.lastName}`
        } else {
            // CONTACT_TYPE.COMPANY
            ownerLabel = t('view.property.general.owner.contactCompany')
            ownerValue = owner[contactResponseFields.CONTACT_COMPANY]?.name
        }

        return [
            {
                id: propertyResponseFields.OWNER,
                ownerId,
                label: ownerLabel,
                value: ownerValue,
            },
            {
                id: 'address',
                label: t('view.contact.general.address'),
                value: address && <span dangerouslySetInnerHTML={{ __html: address }} />,
            },
            ...phones,
            ...emails,
        ]
    })
}

export function getAgentsViewData(agents) {
    if (!agents) return []
    const { t } = i18next

    return agents.map(({ id, firstName, lastName }) => {
        let value = ''

        if (firstName || lastName) {
            value = `${firstName || ''} ${lastName || ''}`
        }

        return {
            id,
            label: t('app.common.agent'),
            value,
        }
    })
}
