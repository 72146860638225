import dayjs from 'dayjs'
import * as Yup from 'yup'
import { activityResponseFields } from '../../response/activity'
import { activityFields } from './fields'
import i18n from '../../../i18n'

const createActivityDateValidationSchema = () => Yup.object({
    [activityFields.DATE_FROM]: Yup.date(),
    [activityFields.TIME_FROM]: Yup.string(),
    [activityFields.DATE_TO]: Yup.date().min(Yup.ref(activityFields.DATE_FROM), i18n.t('activities.dateValidation.dateTo')),
    [activityFields.TIME_TO]: Yup.string().test({
        test: function (value) {
            const timeFrom = this.parent[activityFields.TIME_FROM]

            if (!timeFrom || !value) {
                return true
            }

            const dateFrom = this.parent[activityFields.DATE_FROM]
            const dateTo = this.parent[activityFields.DATE_TO]

            // Only validate time if date from and date to are equal
            if (dayjs(dateFrom).isSame(dayjs(dateTo))) {
                const [hoursTo, minutesTo] = value.split(':')
                const [hoursFrom, minutesFrom] = timeFrom.split(':')
                const timeFromAsMinutes = parseInt(hoursFrom) * 60 + parseInt(minutesFrom)
                const timeToAsMinutes = parseInt(hoursTo) * 60 + parseInt(minutesTo)
                return timeToAsMinutes > timeFromAsMinutes
            }

            return true
        },
        message: i18n.t('activities.dateValidation.timeTo'),
    }),
})

export const activityEventValidaton = (data, config) => {
    const initialValues = {
        [activityFields.ID]: data?.id ?? '',
        [activityFields.TITLE]: data?.title ?? '',
        [activityFields.ACTIVITY_TYPE_ID]: data?.[activityResponseFields.ACTIVITY_TYPE]?.id ?? '',
        [activityFields.DATE_FROM]: data?.[activityResponseFields.DATE_FROM] ?? '',
        [activityFields.TIME_FROM]: data?.[activityResponseFields.TIME_FROM] ?? '',
        [activityFields.DATE_TO]: data?.[activityResponseFields.DATE_TO] ?? '',
        [activityFields.TIME_TO]: data?.[activityResponseFields.TIME_TO] ?? '',
        [activityFields.DURATION_DAY]: data?.[activityResponseFields.DURATION_DAY] ?? false,
        [activityFields.COMPLETED]: data?.[activityResponseFields.COMPLETED] ?? false,
        [activityFields.ACTIVITY_LOCATION]: data?.location ?? '',
        [activityFields.CONTACT_ID]: data?.[activityResponseFields.CONTACT]?.id ?? '',
        [activityFields.CONTACT_COMPANY_COLLECTION]:
            data?.contactCompany?.map((x) => ({
                [activityFields.CONTACT_ID]: x?.id?.toString(),
            })) ?? [],
        [activityFields.ACTIVITY_AGENT_GROUP]: data?.[activityResponseFields.ACTIVITY_AGENT]?.map((item) => ({
            [activityFields.AGENT_ID]: item?.[activityResponseFields.AGENT]?.id,
            [activityFields.NOTIFICATION_SEND]: item?.[activityResponseFields.NOTIFICATION_SEND] === 1 ? true : false,
        })) ?? [
            {
                [activityFields.AGENT_ID]: config?.user?.id ?? 1,
                [activityFields.NOTIFICATION_SEND]: false,
            },
        ],
        [activityFields.ACTIVITY_REMINDER_GROUP]:
            data?.[activityResponseFields.ACTIVITY_REMINDER]?.map((item) => ({
                [activityFields.REMINDER_TYPE]: item?.[activityResponseFields.REMINDER_TYPE],
                [activityFields.REMINDER_SEND]: item?.[activityResponseFields.REMINDER_SEND] === 1 ? true : false,
            })) ?? [],
        [activityFields.PROPERTY_ID]: data?.[activityResponseFields.PROPERTY]?.map((item) => item.id.toString()) ?? [],
        [activityFields.PROJECT_ID]: data?.[activityResponseFields.PROJECT]?.map((item) => item.id.toString()) ?? [],
        [activityFields.NOTES]: data?.notes ?? '',
    }
    const validationSchema = {
        [activityFields.TITLE]: Yup.string().required(i18n.t('modal.activity.error.activityTitle')),
        [activityFields.ACTIVITY_TYPE_ID]: Yup.number().required(i18n.t('modal.activity.error.activityType')),
        [activityFields.DURATION_DAY]: Yup.bool(),
        [activityFields.NOTES]: Yup.string(),
        [activityFields.ACTIVITY_AGENT_GROUP]: Yup.array().of(
            Yup.object().shape({
                [activityFields.AGENT_ID]: Yup.number(),
                [activityFields.NOTIFICATION_SEND]: Yup.bool(),
            })
        ),
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema).concat(createActivityDateValidationSchema()),
    }

    return validationObject
}

export const activityTaskValidaton = (data, config) => {
    const initialValues = {
        [activityFields.ID]: data?.id ?? '',
        [activityFields.TITLE]: data?.title ?? '',
        [activityFields.ACTIVITY_TYPE_ID]: data?.[activityResponseFields.ACTIVITY_TYPE]?.id ?? '',
        [activityFields.DATE_FROM]: data?.[activityResponseFields.DATE_FROM] ?? '',
        [activityFields.TIME_FROM]: data?.[activityResponseFields.TIME_FROM] ?? '',
        [activityFields.DATE_TO]: data?.[activityResponseFields.DATE_TO] ?? '',
        [activityFields.TIME_TO]: data?.[activityResponseFields.TIME_TO] ?? '',
        [activityFields.DURATION_DAY]: data?.[activityResponseFields.DURATION_DAY] ?? false,
        [activityFields.COMPLETED]: data?.[activityResponseFields.COMPLETED] ?? false,
        [activityFields.CONTACT_ID]: data?.[activityResponseFields.CONTACT]?.id ?? '',
        [activityFields.CONTACT_COMPANY_COLLECTION]:
            data?.contactCompany?.map((x) => ({
                [activityFields.CONTACT_ID]: x?.id?.toString(),
            })) ?? [],
        [activityFields.ACTIVITY_AGENT_GROUP]: data?.[activityResponseFields.ACTIVITY_AGENT]?.map((item) => ({
            [activityFields.AGENT_ID]: item?.[activityResponseFields.AGENT]?.id,
            [activityFields.NOTIFICATION_SEND]: item?.[activityResponseFields.NOTIFICATION_SEND] ? true : false,
        })) ?? [
            {
                [activityFields.AGENT_ID]: config?.user?.id ?? 1,
                [activityFields.NOTIFICATION_SEND]: false,
            },
        ],
        [activityFields.ACTIVITY_REMINDER_GROUP]:
            data?.[activityResponseFields.ACTIVITY_REMINDER]?.map((item) => ({
                [activityFields.REMINDER_TYPE]: item?.[activityResponseFields.REMINDER_TYPE],
                [activityFields.REMINDER_SEND]: item?.[activityResponseFields.REMINDER_SEND] === 1 ? true : false,
            })) ?? [],
        [activityFields.PROPERTY_ID]: data?.[activityResponseFields.PROPERTY]?.map((item) => item.id.toString()) ?? [],
        [activityFields.PROJECT_ID]: data?.[activityResponseFields.PROJECT]?.map((item) => item.id.toString()) ?? [],
        [activityFields.NOTES]: data?.notes ?? '',
    }
    const validationSchema = {
        [activityFields.TITLE]: Yup.string().required(i18n.t('modal.activity.error.activityTitle')),
        [activityFields.ACTIVITY_TYPE_ID]: Yup.number().required(i18n.t('modal.activity.error.activityType')),
        [activityFields.DURATION_DAY]: Yup.bool(),
        [activityFields.CONTACT_ID]: Yup.number(),

        [activityFields.ACTIVITY_AGENT_GROUP]: Yup.array().of(
            Yup.object().shape({
                [activityFields.AGENT_ID]: Yup.number(),
                [activityFields.NOTIFICATION_SEND]: Yup.bool(),
            })
        ),
        [activityFields.NOTES]: Yup.string(),
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema).concat(createActivityDateValidationSchema()),
    }

    return validationObject
}

export const activityReminderValidaton = (data, config) => {
    const initialValues = {
        [activityFields.ID]: data?.id ?? '',
        [activityFields.TITLE]: data?.title ?? '',
        [activityFields.ACTIVITY_TYPE_ID]: data?.[activityResponseFields.ACTIVITY_TYPE]?.id ?? '',
        [activityFields.DATE_FROM]: data?.[activityResponseFields.DATE_FROM] ?? '',
        [activityFields.TIME_FROM]: data?.[activityResponseFields.TIME_FROM] ?? '',
        [activityFields.DATE_TO]: data?.[activityResponseFields.DATE_TO] ?? '',
        [activityFields.TIME_TO]: data?.[activityResponseFields.TIME_TO] ?? '',
        [activityFields.DURATION_DAY]: data?.[activityResponseFields.DURATION_DAY] ?? false,
        [activityFields.COMPLETED]: data?.[activityResponseFields.COMPLETED] ?? false,
        [activityFields.CONTACT_ID]: data?.[activityResponseFields.CONTACT]?.id ?? '',
        [activityFields.CONTACT_COMPANY_COLLECTION]:
            data?.contactCompany?.map((x) => ({
                [activityFields.CONTACT_ID]: x?.id?.toString(),
            })) ?? [],
        [activityFields.ACTIVITY_AGENT_GROUP]: data?.[activityResponseFields.ACTIVITY_AGENT]?.map((item) => ({
            [activityFields.AGENT_ID]: item?.[activityResponseFields.AGENT]?.id,
            [activityFields.NOTIFICATION_SEND]: item?.[activityResponseFields.NOTIFICATION_SEND] !== 1 ? true : false,
        })) ?? [
            {
                [activityFields.AGENT_ID]: config?.user?.id ?? 1,
                [activityFields.NOTIFICATION_SEND]: false,
            },
        ],
        [activityFields.ACTIVITY_REMINDER_GROUP]: data?.activityReminderGroup ?? [],
        [activityFields.PROPERTY_ID]: data?.[activityResponseFields.PROPERTY]?.map((item) => item.id.toString()) ?? [],
        [activityFields.PROJECT_ID]: data?.[activityResponseFields.PROJECT]?.map((item) => item.id.toString()) ?? [],
        [activityFields.NOTES]: data?.notes ?? '',
    }
    const validationSchema = {
        [activityFields.TITLE]: Yup.string().required(i18n.t('modal.activity.error.activityTitle')),
        [activityFields.ACTIVITY_TYPE_ID]: Yup.number().required(i18n.t('modal.activity.error.activityType')),
        [activityFields.DURATION_DAY]: Yup.bool(),
        [activityFields.CONTACT_ID]: Yup.number(),

        [activityFields.ACTIVITY_AGENT_GROUP]: Yup.array().of(
            Yup.object().shape({
                [activityFields.AGENT_ID]: Yup.number(),
                [activityFields.NOTIFICATION_SEND]: Yup.bool(),
            })
        ),

        [activityFields.NOTES]: Yup.string(),
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema).concat(createActivityDateValidationSchema()),
    }

    return validationObject
}
