import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import { formatDateTime, formatNumberWithTwoDecimals } from '../../../../utils/general'
import { renderAreaUnit, renderCurrency } from '../../../../utils/property'
import AppPermissionsSwitcher from '../../../AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../../AppPermissionsSwitcher/constants/actions'

const SearchResultItems = (props) => {
    const {
        id,
        linkName,
        updated,
        contactCompany,
        contactPerson,
        projectTitle,
        price,
        currency,
        area,
        areaUnit,
        propertyType,
        propertyStatus,
        agentId,
        agencyOfficeId,
    } = props
    let name = ''
    const link = `/${linkName}/${id}`
    const updatedRender = updated

    switch (linkName) {
        case 'companies':
            name = contactCompany.name
            break
        case 'contacts':
            name = `${contactPerson?.firstName} ${contactPerson?.lastName}`
            break
        case 'projects': {
            name = projectTitle ? `ID${id}, ${projectTitle}` : `ID${id}`
            break
        }
        case 'properties': {
            const priceRender = price ? `${formatNumberWithTwoDecimals(price)}${renderCurrency(currency, false)},` : ''
            const areaRender = area && `${area} ${renderAreaUnit(areaUnit)}`
            name = `ID${id}, ${propertyType?.name}, ${priceRender} ${areaRender}`
            break
        }
        default:
            break
    }

    return (
        <AppPermissionsSwitcher
            permission="ModulePermissions"
            itemAgentId={agentId}
            itemAgentOfficeId={agencyOfficeId}
            module={linkName}
            action={action.view}
        >
            {({ hasModulePermission }) => (
                <Link
                    to={hasModulePermission ? link : '#'}
                    className="dropdown-item notify-item py-2"
                    style={{ cursor: hasModulePermission ? 'pointer' : 'not-allowed' }}
                >
                    <div className="d-flex flex-wrap">
                        <h6 className="d-inline-block fs-13 fw-light m-0">{name} </h6>
                        <span className="fs-11 mb-0 text-secondary fw-light"> , ({formatDateTime(updatedRender)})</span>
                        <Badge color="favorite" className="fs-12 fw-medium ms-2">
                            {propertyStatus?.name}
                        </Badge>
                    </div>
                </Link>
            )}
        </AppPermissionsSwitcher>
    )
}

export default SearchResultItems
