import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { setDefaultColumnSort } from '../api/helpers'
import { USER_SETTINGS } from '../common/constants'
import responseFields from '../common/constants/responseFields'
import { companyResponseFields } from '../common/response/company'
import { contactResponseFields } from '../common/response/contact'
import { projectResponseFields } from '../common/response/project'
import { propertyResponseFields } from '../common/response/property'
import { updateCurrentSettings } from '../store/auth/actions'
import { selectCurrentUserId, selectCurrentUserSettings } from '../store/auth/selectors'

const DEFAULT_HIDDEN_COLUMNS = {
    project: [`${projectResponseFields.ID}`, `${projectResponseFields.PROJECT_DEVELOPMENT_STAGE}`, `${projectResponseFields.PROJECT_END_DATE}`],
    property: [
        'id',
        'prilike',
        'agentPhoneNumber',
        'propertyPhoto',
        'eleveavtor',
        'webSync',
        'contact_name',
        'contact_number',
        `${propertyResponseFields.PROPERTY_SERVICE}`,
        `${propertyResponseFields.PROPERTY_SUB_TYPE}`,
        `${propertyResponseFields.COUNTRY}`,
        `${propertyResponseFields.COUNTY}`,
        `${propertyResponseFields.STREET}`,
        `${propertyResponseFields.STREET_NUMBER}`,
        `${propertyResponseFields.OWNER}`,
        `${propertyResponseFields.PROPERTY_ROOMS_NO}`,
        `${propertyResponseFields.BEDROOMS_NO}`,
        `${propertyResponseFields.MAX_CONTRUCTION_AREA}`,
        `${propertyResponseFields.DISTANCE_SEA}`,
        `${propertyResponseFields.AREA_GARDEN}`,
        `${propertyResponseFields.PLOT_AREA}`,
        'owner_phone1',
        'owner_phone2',
        `${propertyResponseFields.PROPERTY_HEATING}`,
        `${propertyResponseFields.CONSTRUCTION_YEAR}`,
        `${propertyResponseFields.AVAILABLE_FROM_DATE}`,
        `${responseFields.PROPERTY_SUPPLY_SOURCE}`,
        `${propertyResponseFields.CONTRACT_DATE}`,
    ],
    company: [
        'id',
        `${companyResponseFields.CONTACT_COUNTRY}`,
        `${companyResponseFields.CITY}`,
        `${companyResponseFields.STREET}`,
        `${companyResponseFields.STREET_NUMBER}`,
        `${companyResponseFields.CONTACT_PHONE}2`,
        `${companyResponseFields.UPDATED}`,
        `${companyResponseFields.INITIAL_CONTACT_DATE}`,
        `${companyResponseFields.DATA_SOURCE}_name`,
        'contactProfileType',
        'contactProfileStatus',
        'demandPriceTo',
        'lastActivityComment',
    ],
    contact: [
        `${contactResponseFields.CITY}`,
        `${contactResponseFields.STREET}`,
        `${contactResponseFields.STREET_NUMBER}`,
        `${contactResponseFields.CONTACT_PHONE}2`,
        `${contactResponseFields.CONTACT_PERSON_COMPANY}`,
        `${contactResponseFields.DATA_SOURCE}_name`,
        `${contactResponseFields.CONTACT_TYPE}`,
        `${contactResponseFields.UPDATED}`,
        `${contactResponseFields.INITIAL_CONTACT_DATE}`,
        `${contactResponseFields.CONTACT_DEFINITION}_name`,
        'contactProfileType',
        'demandPriceTo',
        'lastActivityComment',
    ],
    relatedProperties: [
        'id',
        'prilike',
        'agentPhoneNumber',
        'propertyPhoto',
        'eleveavtor',
        'webSync',
        'contact_name',
        'contact_number',
        `${propertyResponseFields.PROPERTY_SERVICE}`,
        `${propertyResponseFields.PROPERTY_SUB_TYPE}`,
        `${propertyResponseFields.COUNTRY}`,
        `${propertyResponseFields.COUNTY}`,
        `${propertyResponseFields.STREET}`,
        `${propertyResponseFields.STREET_NUMBER}`,
        `${propertyResponseFields.OWNER}`,
        `${propertyResponseFields.PROPERTY_ROOMS_NO}`,
        `${propertyResponseFields.BEDROOMS_NO}`,
        `${propertyResponseFields.MAX_CONTRUCTION_AREA}`,
        `${propertyResponseFields.DISTANCE_SEA}`,
        `${propertyResponseFields.AREA_GARDEN}`,
        `${propertyResponseFields.PLOT_AREA}`,
        'owner_phone1',
        'owner_phone2',
        `${propertyResponseFields.PROPERTY_HEATING}`,
        `${propertyResponseFields.CONSTRUCTION_YEAR}`,
        `${propertyResponseFields.AVAILABLE_FROM_DATE}`,
        `${propertyResponseFields.DATA_SOURCE}`,
        `${propertyResponseFields.CONTRACT_DATE}`,
    ],
}

const DEFAULT_COLUMN_SORT = {
    opportunityActivityTable: [{ id: 'dateFrom', desc: true }],
    opportunityContactTable: [{ id: 'contactPersonName', desc: false }],
    default: [{ id: 'id', desc: true }],
}

export const useColumnSettings = (appModule) => {
    const dispatch = useDispatch()

    const { userSettings, userId } = useSelector((state) => ({
        userSettings: selectCurrentUserSettings(state),
        userId: selectCurrentUserId(state),
    }))

    const hiddenColumnsKey = `${appModule}_${USER_SETTINGS.HIDDEN_COLUMNS}`
    const columnOrderKey = `${appModule}_${USER_SETTINGS.COLUMN_ORDER}`
    const columnSortKey = `${appModule}_${USER_SETTINGS.COLUMN_SORT}`

    const userSettingsLoaded = Boolean(userSettings?.[hiddenColumnsKey])

    const setDefaultColumnSort = (appModule) => {
        return DEFAULT_COLUMN_SORT[appModule] || DEFAULT_COLUMN_SORT.default
    }

    const hiddenColumnsDirtyCheck = (hiddenColumns) => {
        // Return as dirty to trigger update
        if (!userSettingsLoaded) {
            return true
        }

        return !isEqual([...hiddenColumns]?.sort(), [...userSettings[hiddenColumnsKey]]?.sort())
    }

    const columnSettingsDirtyCheck = (columnSettings, columnKey) => {
        // Return as dirty to trigger update
        if (!userSettingsLoaded) {
            return true
        }

        return !isEqual(columnSettings, userSettings?.[columnKey])
    }

    const updateHiddenColumns = (hiddenColumns) => {
        if (hiddenColumnsDirtyCheck(hiddenColumns)) {
            dispatch(
                updateCurrentSettings({
                    userId,
                    userSettings: {
                        ...userSettings,
                        [hiddenColumnsKey]: hiddenColumns,
                    },
                })
            )
        }
    }

    const updateColumnSettings = (columnSettings, columnKey) => {
        if (columnSettingsDirtyCheck(columnSettings, columnKey)) {
            dispatch(
                updateCurrentSettings({
                    userId,
                    userSettings: {
                        ...userSettings,
                        [columnKey]: columnSettings,
                    },
                })
            )
        }
    }

    const updateColumnOrder = (columnOrder) => {
        updateColumnSettings(columnOrder, columnOrderKey)
    }

    const updateColumnSort = (columnSort) => {
        updateColumnSettings(columnSort, columnSortKey)
    }

    return {
        hiddenColumns: userSettings?.[hiddenColumnsKey] || DEFAULT_HIDDEN_COLUMNS[appModule] || [],
        updateHiddenColumns,
        columnOrder: userSettings?.[columnOrderKey] || null,
        updateColumnOrder,
        columnSort: userSettings?.[columnSortKey] || setDefaultColumnSort(appModule),
        updateColumnSort,
    }
}
