import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import {
    opportunityShowActivity,
    opportunityShowContact,
    selectOpportunityDropDownButtonText,
    selectOpportunityTitle,
} from '../../../store/properties'

const OpportunityDropDown = ({ setOpportunityParams }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { title, buttonText } = useSelector((state) => ({
        title: selectOpportunityTitle(state),
        buttonText: selectOpportunityDropDownButtonText(state),
    }))

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggle = () => setDropdownOpen((prevState) => !prevState)

    return (
        <div className="d-flex align-items-center flex-grow-1 mt-2 mb-2">
            <p className="m-0">{t(title)}</p>
            <Dropdown
                className="ms-2"
                isOpen={dropdownOpen}
                toggle={toggle}
                direction="down"
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <DropdownToggle tag="button" className="btn btn-light border border-2 d-flex justify-content-between">
                    {t(buttonText)} <i className="mdi mdi-chevron-down ms-2"></i>
                </DropdownToggle>
                <DropdownMenu className="w-100">
                    <DropdownItem
                        onClick={() => {
                            dispatch(opportunityShowActivity())
                            setOpportunityParams({})
                        }}
                    >
                        {t('app.common.activities')}
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => {
                            dispatch(opportunityShowContact())
                            setOpportunityParams({})
                        }}
                    >
                        {t('app.common.contacts')}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default OpportunityDropDown
